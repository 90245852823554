@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/GoogleMap/GoogleMap.js
////

/// styles for product filters
.google-map-container {
    height: 100%;

    .google-map {
        height: 100%;

        img {
            max-height: none;
        }

        .gm-control-button {
            margin: 10px;
            padding: .4em 0 0;
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 2px;
            background-color: $base-color__bg_light;
            color: $base-color__text_grey;
            box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
            user-select: none;

            i {
                opacity: .75;
            }

            &:hover {
                background-color: $base-color__bg_light;
                color: $base-color__text_dark;

                i {
                    opacity: 1;
                }
            }

        }

        .gm-control-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.google-map {
    width: 100%;
    height: 100%;
}

#location-map {
    height: 300px;
}
