.brand-content {
    columns: 2;
    column-gap: $grid;

    h1, h2 {
        column-span: all;
    }

    h3:first-of-type {
        margin-top: 0;
    }

    img {
        width: 100%;
    }

    p {
        margin-top: 0;
        break-inside: avoid;
    }

    blockquote {
        column-span: all;
        margin-left: 0;
    }

    a {
        text-decoration: underline;
    }

    strong a {
        font-weight: bold;
    }

    @media screen and (max-width: $screen-width-lg) {
        columns: 1;
    }
}
