@import '~styles/_settings/utils';

////
/// @group 04: Pages
/// Script Page: assets/scripts/pages/start.js
////

/// styles for homepage
.start-page {
    .pick-your-style,
    .store-locator {

        @include for-screen-gte__xl {
            margin-top: (2*$grid);
            margin-bottom: (4*$grid);
        }

        @include for-screen-gte__hd {
            margin-top: (4*$grid);
            margin-bottom: (8*$grid);
        }

        @include for-screen-lt__xl {
            margin-top: (2*$grid);
            margin-bottom: (2*$grid);
        }
    }

    .pick-your-style {

        p.intro {
            line-height: 1.75;

            @include for-screen-gte__xxl {
                max-width: 1500px;
            }

            @include for-screen-lt__md {
                text-align: left;
            }
        }

        .full-text {

            max-width: 80%;

            @include for-screen-gte__xl {
                margin-bottom: (2*$grid);
                margin-top: (2*$grid);
            }

            @include for-screen-gte__hd {
                margin-bottom: (4*$grid);
                margin-top: (4*$grid);
            }

            @include for-screen-lt__xl {
                margin-bottom: $grid;
                margin-top: $grid;
            }

            @include for-screen-lt__md {
                max-width: 100%;
                text-align: left;
            }

            p {
                line-height: 1.46;
                @include set-font-sizes(1rem, 1rem, 1rem, 1rem, 1.25rem, 1.5rem);
            }

        }
    }

    .text-content {
        a {
            font-weight: bold;
        }
    }

    .column-list {
        a {
            font-weight: normal;
        }
    }

    .brands {
        .column-list {
            margin-bottom: $grid * 4;
        }
    }
}
