ul.country-list {
    columns: 1;

    @media screen and (min-width: $screen-width-sm) {
        columns: 2;
    }

    @media screen and (min-width: $screen-width-xl) {
        columns: 3;
    }

    .country-entry {
        break-inside: avoid;
    }
}
