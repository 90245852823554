////
/// @group 01: Globals
/// global elements imported in Layout.scss which are valid cross component
/// do not import the globals in standard components!!!!
////
svg {
    &.icon {
        path,
        circle,
        line {
            fill: none;
            stroke: $base-color__border_grey;
            stroke-miterlimit: 10;
            stroke-width: 1px;
        }
    }

    &.arrow-down {
        width: 26px;
        height: 13px;

        @include for-screen-lt__xxl {
            width: 18px;
            height: 9px;
        }
    }

    &.arrow-up {
        width: 26px;
        height: 13px;
    }

    &.arrow-up-right {
        width: 27px;
        height: 27px;
    }

    &.arrow-right {
        width: 27px;
        height: 54px;
    }

    &.arrow-left {
        width: 27px;
        height: 54px;
    }

    &.circled-star {
        g {
            path {
                stroke: white;
                fill: white;
            }

            g {
                path {
                    fill: $color_premium_pro;
                }
            }
        }
    }

    &.close {
        width: 13px;
        height: 13px;
    }

    &.search {
        width: 35px;
        height: 35px;
    }

    &.blog {
        width: 44px;
        height: 35px;
    }

    &.heart {
        width: 43px;
        height: 35px;
    }

    &.star {
        width: 34px;
        height: 34px;

        path {
            fill: $base-color__border_grey;
            stroke-width: 1px;
        }
    }

    &.clubbonus {
        width: 65px;
        height: 60px;

        path {
            stroke-width: 0;
        }

        path.logo {
            fill: $base-color__text_dark;
            stroke: $base-color__text_dark;
        }

        path.text {
            fill: #FFF;
        }
    }

    &.location {
        width: 29px;
        height: 38px;
    }

    &.nope,
    &.check {
        width: 20px;
        height: 20px;

        path,
        circle,
        line {
            fill: $base-color__border_grey;
            stroke: transparent;
            stroke-miterlimit: 10;
            stroke-width: 1px;
        }
    }

    &.user {
        width: 29px;
        height: 29px;
    }

}

.button,
button,
a {
    svg {
        &.arrow-down,
        &.arrow-up {
            padding: 0 0 0 $element-spacing-em;

            @include for-screen-lt__xxl {
                padding-left: $element-spacing-em-half;
            }
        }

        &.blog {
            padding: 0 $element-spacing-em 0 0;
            margin-top: -0.2em; // needed to counteract optical allusion of vertical icon lines
        }

        &.heart {
            margin: .3em $element-spacing-em 0 0;
        }

        &.location {
            margin: .1em $element-spacing-em 0 0;
        }

        &.user {
            margin: .1em $element-spacing-em 0 0;
        }
    }

    @include set-colors-hover(false, false, false) {
        svg {
            &.icon {
                path,
                circle,
                line {
                    stroke: $base-color__border_light;
                }
            }

            &.blog,
            &.heart,
            &.location,
            &.user,
            &.arrow-left,
            &.arrow-right,
            &.arrow-up-right {
                path,
                circle,
                line {
                    stroke: $base-color__border_grey;
                }
            }
        }
    }
}
