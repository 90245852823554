@import '~styles/_settings/utils';

////
/// @group 04: Pages
/// Script Page: assets/scripts/pages/login.js
////

/// styles for user registration page

.registration-page {
    @include details-twocol-like-footercol;

    .register-form {
        .already-registered {
            margin: 0;
            float: left;

            a {
                font-weight: bold;
            }
        }
    }
}
