.faq {
    details {
        margin-bottom: $grid;

        summary {
            font-size: 20px;
        }

        > .answer {
            opacity: 0.8;
            font-style: italic;
        }
    }

    a {
        font-weight: bold;
    }
}
