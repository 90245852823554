////
/// @group 01: Globals
/// global elements imported in Layout.scss which are valid cross component
/// do not import the globals in standard components!!!!
////
@import '../_settings/_utils_el_button';

/// standard
%button {
    @include base-btn();
    @include base-btn-hover();
    ///
    ///
    ///
    &.is-primary {
        @include set-colors($base-color__text_light, $base-color__bg_dark, $base-color__border);
        @include set-colors-hover($base-color__text_dark,$base-color__bg_light, $base-color__border_dark);
    }

    &.is-secondary {
        @include set-colors($base-color__text_light, transparent, $base-color__border_light);
        @include set-colors-hover($base-color__text_light, $base-color__bg_light-50, $base-color__bg_light);
    }

    &.is-borderless {
        @include set-colors(false, transparent, transparent);
        @include set-colors-hover($base-color__text_light, $base-color__bg_dark, transparent);
    }

    &.is-close {
        line-height: 1.1;
        padding: .5em .3em .2em;
        width: auto;
        border-width: 0;

        svg.icon {
            width: 21px;
            height: 21px;
        }

        @include set-colors-hover($base-color__text_dark,$base-color__bg_light, $base-color__border_dark) {
            svg.icon {
                path {
                    stroke: $base-color__text_dark;
                }
            }
        };

    }
}

.button,
input[type='button'],
button {
    @extend %button;
}
