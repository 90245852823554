////
/// @group 00: Utils
/// has to be included and is usable in every component
////

/// default text link
/// @output styling for all states except visited
/// Default text link
@mixin text-link {
    text-decoration: none;
    color: $base-color__text;
    display: inline;
    @extend %text-light;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }
}

/// Default linked img
@mixin img-link {
    text-decoration: none;
    // prevent 3px bottom space of image
    line-height: 1em;
    display: block;

    img {
        display: block;
    }
}

/// @TODO
@mixin arrow-up-link ($height: 20px, $border-color: $base-color__border_grey, $bg-color: $base-color__bg_light) {

    position: relative;
    display: block;
    margin: 0 auto;
    z-index: 10;
    width: ($height * 2);
    height: $height;
    padding: $height;
    border: 1px solid $bg-color;

    .txt {
        @extend %a11y-hidden;
    }

    &::after,
    &::before {
        position: absolute;
        bottom: $height;
        left: 50%;
        display: block;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        pointer-events: none;
    }

    &::after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: $bg-color;
        border-width: $height;
        margin-left: (-1 * $height);
    }

    &::before {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: $border-color;
        border-width: (2 + $height);
        margin-left: -(2 + $height);
    }

   @include set-colors-hover(false, $base-color__bg_grey, $base-color__border_grey) {
        &::after {
            border-bottom-color: $base-color__bg_grey;
        }
   }
}
