.payment-process {
    .pay-now {
        margin-top: $grid;
    }

    .try-free-note {
        color: grey;
    }

    @import './PromotionCode';
}

.payment-method-select {
    display: flex;
    flex-direction: column;
    align-items: center;

    .field {
        margin: $grid 0 0;
    }

    .button {
        .label {
            margin: 0;
        }

        .note {
            opacity: 0.5;
        }

        .note,
        .free-trial {
            text-transform: none;
        }
    }
}

@import './Upgrade';
