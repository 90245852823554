.cookie-banner {
    position: fixed;
    right: $grid;
    bottom: $grid;
    padding: $grid;
    border: 1px solid $base-color__border;
    transition: opacity ease-in 1s;
    background: $base-color__bg;
    z-index: 1000;
    max-width: 60vw;
    font-size: 90%;

    .buttons {
        text-align: right;

        button {
            margin-left: $grid;
        }
    }
}
