@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/StoreLocator/StoreList.js
////

/// styles for error, success and warning messages
ul {
    &.field-error-list,
    &.field-message-list,
    &.field-warning-list {
        list-style-type: none;
        list-style-position: inside;
        margin: 0;
    }

}

.field-error-list,
.field-message-list,
.field-warning-list {
    border: 1px solid;
    padding: $grid-half;

    .name,
    h5.name {
        margin: 0 0 $grid-half 0;
        text-transform: uppercase;
    }
}

.field-error-list {
    border-color: $base-color__border_error;
    background-color: $base-color__bg_error;
    color: $base-color__text_error;
}

.field-message-list {
    border-color: $base-color__border_success;
    background-color: $base-color__bg_success;
    color: $base-color__text_success;
}

.field-warning-list {
    border-color: $base-color__border_warning;
    background-color: $base-color__bg_warning;
    color: $base-color__text_warning;
}

/** modifier classes for simple inline messages */
.is-error,
.is-danger {
    color: $base-color__text_error;
    font-weight: 500;
}

.is-warning {
    color: $base-color__text_warning;
    font-weight: 500;
}

.is-success {
    color: $base-color__text_success;
    font-weight: 500;
}
