@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/ProductRequest/Productinfo.js
////

/// styles for product-info in contact store
.product-info {
    display: flex;
    flex: unset;
    background-color: $base-color__bg_grey;
    margin-bottom: ($grid * 2);
    white-space: nowrap; // prevent sf flex bug distorting image

    .product-img {
        display: block; // prevent sf flex bug distorting image
        width: 50%;
        justify-content: unset;
        img {
            width: 100%;
            height: auto;
        }
    }

    .product-details {
        white-space: normal; // prevent sf flex bug distorting image
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;

        p {
            padding-left: $grid;
            padding-right: $grid;
        }

        span {
            display: block;
        }
    }

    @include for-screen-lt__lg {
        margin-right: 0;
        border-right: 0;
    }
}
