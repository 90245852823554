.show-store-map {
    display: inline;
    position: fixed;
    bottom: $grid;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;

    button {
        display: flex;
        align-items: center;

        :not(:first-child) {
            margin-left: 0.5rem;
        }
    }
}
