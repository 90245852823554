@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/NavBar/NavBar.js
////


$navbarBurgerDistance: .45rem;
$navbarBurgerWidth: ($navbar-height-xl - $grid);

/// styles for navbar with logo and main menu
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    overflow: visible;

    border-bottom: 1px solid $base-color__border_lightgrey;

    @include for-screen-gte__xl { // min-width 1250px
        margin-bottom: $navbar-space-xl;
    }

    @include for-screen-gte__hd { // min-width 1920px
        margin-bottom: $navbar-space-hd;
    }

    @include for-screen-lt__xl { // max-width 1250px
        max-height: $navbar-height-xl;
        background-color: #fff;
    }

    .navbar-container {
        @include container;
    }

    .section {
        margin: 0;
        width: 100%;
        display: flex;
        align-items: stretch;
        background-color: $base-color__bg_light;

        @include for-screen-gte__xl { // min-width 1250px
            padding-top: $navbar-space-xl;
            padding-bottom: 0;
        }

        @include for-screen-gte__hd { // min-width 1920px
            padding-top: $navbar-space-hd;
        }
    }

    .text-content {
        position: relative;
        display: flex;
        align-items: stretch;
        flex-grow: 1;
        background-color: $base-color__bg_light;
        margin: 0 auto;
        padding-right: 0;

        @include for-screen-lt__xl {
            padding-left: $grid;
            padding-right: $grid;
        }
    }

    .border-content {
        @include for-screen-lt__xl {
            border-width: 0
        }
    }

    // logo container in navbar
    .logo {
        display: flex;
        align-items: stretch;
        flex-shrink: 0;
        margin-right: $grid;

        .nav-item {
            @include img-link;
        }

        /// logo img / .brand defines the height of the whole navbar, we have to make sure that this is the case
        /// otherwise the position of the filter row does not fit
        .brand {
            display: block;
            box-sizing: border-box;
            width: auto;

            @include for-screen-gte__xxl { // min-width 1600px
                height: $navbar-height-hd;
                padding-top: 26px;
                padding-bottom: 27px;
            }

            @include for-screen-lt__xxl { // max-width 1600px
                height: $navbar-height-xxl;
                padding-top: 21px;
                padding-bottom: 22px;
            }

            @include for-screen-lt__xl { // max-width 1250px
                height: $navbar-height-xl;
                padding-top: 16px;
                padding-bottom: 17px;
            }
        }
    }

    // menu is flex with two flex parts left and right
    .menu,
    .menu .left,
    .menu .right {

        // items in general
        .menu-item {
            text-transform: uppercase;
            @extend %text-light;

            @include for-screen-gte__hd {
                font-size: $font-size__hd_20;
            }

            .location-widget {
                width: 200%;
                max-width: 100vw;
            }

            .location-widget-header {
                input {
                    width: 100%;
                    border-width: 0 0 1px 0;
                    font-size: .83rem;
                    margin: 0;
                }
            }

            &.current-location {
                $item-height: ($grid * 2);
                $icon-size: 16px;

                .notification {
                    display: block;
                    border: 1px solid;
                    text-align: left;
                    text-transform: none;

                    @include set-colors(false, $base-color__bg_grey, $base-color__border_grey);
                }

                .is-close {
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 100;
                    background-color: transparent;
                    line-height: 1em;
                    padding: $grid-half;

                    svg {
                        width: $icon-size;
                        height: $icon-size;

                        path {
                            stroke: $base-color__text_dark;
                            stroke-width: 2px;
                        }
                    }

                    &:hover {
                        svg path {
                            stroke: $base-color__text_lightgrey;
                        }
                    }
                }

                p {
                    margin: 0;
                    padding: $grid-half $item-height $grid-half $grid-half;
                    font-size: .83rem;
                    text-align: left;
                }

                .btn-area {
                    text-align: right;

                    button {
                        width: 100%;
                        border-color: transparent;
                        border-top-color: $base-color__border_dark;

                        @include set-colors-hover(false, $base-color__bg_grey, false);
                    }
                }
            }
        }

        // items desktop
        @include for-screen-gte__xl {
            display: flex;
            align-items: stretch;
            flex-grow: 1;
            flex-shrink: 0;
            justify-content: flex-end;
            margin-left: auto;

            .menu-item {
                cursor: pointer;
                justify-content: center;
                display: flex;
                flex-grow: 0;
                flex-shrink: 0;
                align-items: center;
                box-sizing: border-box;
                text-align: center;
                text-decoration: none;
                padding: 1em;

                @include set-colors-hover(false, $base-color__bg_grey, false);

                svg {
                    width: auto;
                    height: 1.2em;
                    margin: 0;
                }

                &.current-location {
                    position: relative;

                    .notification {
                        position: absolute;
                        top: 100%;
                        right: 0;
                        min-width: 20vw;

                        &.location-widget {
                            border: 1px solid $base-color__border_grey;
                            left: auto;

                            &.hidden {
                                display: none !important;
                            }
                        }
                    }
                }

                &.user {
                    max-width: 15vw;
                }
            }
        }


/* + + +  items mobile not visble by default  + + + */
// @TODO CurrentLocationItem Components have to be refactored to remove overlays from menu link
// otherwise the the mobile menu cannot be set to display none

        @include for-screen-lt__xl { // max-width 1250px
            .menu-item {
                display: none;

                &.current-location {
                    position: absolute;
                    display: block;
                    width: 1px;
                    height: 1px;
                    overflow: visible;
                    border: 0;
                    white-space: nowrap;
                    padding: 0;

                    .mobile-only,
                    .current-locator,
                    svg.location  {
                        display: none;
                    }

                    .notification {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        z-index: 40;
                        border: 0 none;

                        &.location-widget {
                            width: 100%;
                            max-width: none;

                            &.hidden {
                                display: none !important;
                            }

                            .notification-content {
                                width: 400px;
                                max-width: 90vw;
                            }
                        }
                    }

                    // top position + right-aligned with navbar
                    .notification-inner {
                        position: relative;
                        top: $navbar-height-xl;
                        width: auto;
                        max-width: $container-max-width-lg;
                        box-sizing: border-box;
                        text-align: right;
                        margin: 0 auto;
                    }

                    // top position + right-aligned with navbar
                    .notification-content {
                        position: absolute;
                        right: $grid;
                        display: inline-block;
                        width: 50vw;
                        max-width: 300px;
                        background-color: $base-color__bg_grey;
                        border: solid 1px $base-color__border_grey;
                        text-align: left;
                        margin: 0;
                    }
                }
            }
        }


        @include for-screen-lt__sm { // max-width 576px
            .menu-item {
                &.current-location {
                    .notification-content {
                        width: auto;
                    }
                }
            }
        }
    }

    .menu .left {
        @include for-screen-gte__xl {
            justify-content: flex-start;
        }
    }

    .menu .right {
        @include for-screen-gte__xl {
            justify-content: flex-end;

            .mobile-only {
                display: none;
            }
        }
    }

    // spacer for fixed navbar has to have the same height as navbar
    + .navbar-footer .section {
        @include for-screen-gte__xl { // min-width 1250px
            height: ($navbar-height-xxl + 2); // + border
            margin-top: $navbar-space-xl;
            margin-bottom: $navbar-space-xl;
        }

        @include for-screen-gte__xxl { // min-width 1600px
            height: ($navbar-height-hd + 2); // + border
        }

        @include for-screen-gte__hd { // min-width 1920px
            margin-top: $navbar-space-hd;
            margin-bottom: $navbar-space-hd;
        }

        @include for-screen-lt__xl { // max-width 1250px
            height: ($navbar-height-xl + 1); // + border
            margin: 0;
        }
    }
}

// toggle button for mobile menu (burger)
.navbar-toggle {
    cursor: pointer;
    position: relative;
    right: 0;
    width: $navbarBurgerWidth;
    display: -webkit-box;
    display: flex;
    margin-left: auto;

    @include for-screen-gte__xl {
        display: none;
    }

    @include set-colors-hover($base-color__text_dark, $base-color__bg_light-50, false) {
        [aria-hidden] {
            background-color: $base-color__bg_dark;
        }
    };

    // animation for burger, part 1
    [aria-hidden] {
        position: absolute;
        right: 4px;
        top: 50%;
        display: block;
        height: 1px;
        width: ($navbarBurgerWidth - 8);
        background-color: $base-color__border_dark;
        transform-origin: center;
        transition-duration: 86ms;
        transition-property: background-color,opacity,transform;
        transition-timing-function: ease-out;

        &:nth-child(1) {
            top: calc(50% - #{$navbarBurgerDistance});
        }

        &:nth-child(3) {
            top: calc(50% + #{$navbarBurgerDistance});
        }
    }
}

.mobile-menu-is-active {
    .navbar {
        .menu {
            position: absolute;
            top: 0;
            right: $grid;
            left: $grid;
            display: block;
            padding-top: ($navbar-height-xl + 2); // 2px for border of navbar

            .language-changer {
                background: white;
            }

            .top-menu-item {
                float: none;
                margin: 0;
                font-size: 1rem;
                font-family: $font-family_sans;
                font-weight: 400;
                font-style: normal;
            }

            .top-menu-item,
            .menu-item {
                display: block;
                border-bottom: 1px solid;
                text-transform: uppercase;
                padding: $grid;

                @include set-colors($base-color__text, $base-color__bg_light, $base-color__border_grey);

                svg {
                    display: none;
                }

                &.current-location {
                    position: static;
                    display: block;
                    width: auto;
                    height: auto;
                    border-bottom: 1px solid;
                    white-space: normal;
                    padding: $grid $grid-half $grid $grid;
                    border-bottom-color: $base-color__border_grey;

                    span.mobile-only {
                        display: inline;
                    }

                    .current-locator {
                        display: block;
                    }
                }
            }
        }
    }

    // animation for burger, part 2
    .navbar-toggle {
        [aria-hidden] {
            &:nth-child(1) {
                transform: translateY($navbarBurgerDistance) rotate(45deg);
            }

            &:nth-child(2) {
                display: none;
            }

            &:nth-child(3) {
                transform: translateY((-1 *$navbarBurgerDistance)) rotate(-45deg);
            }
        }
    }
}
