@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/BlogDetail/BlogNavigation.js
////

/// styles for blog navigation in blog detail page

.blog-navigation {
    display: flex;
    justify-content: space-between;

    a {
        max-width: calc(33.333333% + 1px); // same width like footer col

        svg {
            position: absolute;
            top: 0;
            width: 9px;
            height: 33px;

            @include for-screen-lt__xl {
                top: 2px;
            }

            path {
                transition: all $transition;
                stroke: $base-color__border_dark;

                @include for-screen-lt__xl {
                    stroke-width: 2px;
                }
            }
        }

        .button {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: ($font-size__hd_16 * .88);

            @include for-screen-lt__xl {
                height: 40px;
                width: 40px;
            }

            span {
                @include for-screen-lt__xl {
                    display: none;
                }
            }

            &:hover {
                svg {
                    path {
                        stroke: $base-color__border_light;
                        stroke-width: 2px;
                    }
                }
            }
        }

        &.previous {
            svg {
                right: 10px;

                @include for-screen-lt__xl {
                    left: 50%;
                    right: auto;
                    transform: translateX(-50%);
                }
            }

            .button {
                padding-right: 30px;
            }
        }

        &.next {
            svg {
                left: 10px;

                @include for-screen-lt__xl {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .button {
                padding-left: 30px;
            }
        }

        &.previous,
        &.next {
            display: inline-block;
            height: auto;
            width: auto;
            margin: 0;
            padding: 0;
        }
    }
}
