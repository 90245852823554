@import '../_settings/utils';

////
/// @group 02: Basic Components
/// Script Component: assets/scripts/components/Layout.js
////

/// contains cross component basic elements styles
/// layout styles as same as app styles are an exception to the rule that components must not influence each other, this one does and is supposed to
.layout {
    .main {
        @include container;
    }

    .container {
        @include container;
    }

    .section {
        @include section;
    }

    .main-content {
        @include main-content;

        .section {
            padding-left: 0;
            margin-left: 0;
            padding-right: 0;
            margin-right: 0;
        }
    }

    .text-content {
        @include text-content;
    }

    .form-content {
        @include form-content;
        @include clearfix;
    }

    .border-content {
        @include border-content;
    }

}



