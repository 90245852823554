.info {
    margin-left: 0.3rem;
    margin-right: 0.1rem;
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 1px solid black;
    border-radius: 50%;
    text-align: center;

    cursor: help;

    $space: 1rem;

    &:hover::after {
        content: attr(data-title);
        position: absolute;
        display: block;
        width: 15rem;
        left: 0;
        margin-top: calc(#{$space} / 4);
        padding: calc(#{$space} / 2);
        background-color: white;
        color: black;
        border: 1px solid $base-color__border_lightgrey;
        z-index: 1;
    }
}
