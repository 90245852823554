@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/Overlay.js
////

/// styles for 'match my style' / status messages overlay

#overlay-container {
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    overflow: visible;
}

.overlay {
    $item-height: ($grid * 2);
    $icon-size: 16px;


/* + + +  close button - hover for whole text bg: light gray  + + + */
    .is-close {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: $icon-size;
        height: $icon-size;
        text-align: right;

        svg {
            width: $icon-size;
            height: $icon-size;
            margin-top: calc(($item-height - $icon-size) / 2);
            margin-right: $grid-half;

            path {
                stroke-width: 3px;
            }
        }

        &:hover {
            svg path {
                stroke: $base-color__text_dark;
            }
        }
    }


/* + + +  header messages  + + + */
    &.messages {
        // top position + right-aligned with navbar
        @include container;

        text-align: right;

        @include for-screen-gte__hd { // min-width 1920px
            top: ($navbar-height-hd + 1 + $navbar-space-hd ); // + border-top
        }

        @include for-screen-lt__hd { // max-width 1919px
            top: ($navbar-height-hd + 1 + $navbar-space-xl ); // + border-top
        }

        @include for-screen-lt__xxl { // max-width 1600px
            top: $navbar-height-xxl + 1 + $navbar-space-xl; // + border-top
        }

        @include for-screen-lt__xl { // max-width 1250px
            top: $navbar-height-xl;
        }

        .is-close {
            width: 100%;
            height: 100%;
        }
    }


/* + + +  overlay  + + + */
    &.modal {
        &.is-active {
            @include modal;

            .button-row {
                display: flex;
                justify-content: space-between;
                width: 100%;
                text-transform: uppercase;
            }

/* + + +  match my style  + + + */
            &.my-style-modal {
                .modal-card {
                    @include container;

                    max-height: 90vh;
                    background-color: transparent;

                    @include for-screen-gte__xl { // min-width 1250px
                        top: 141px;
                        max-height: calc(98vh - 141px);
                    }

                    @include for-screen-gte__xxl { // min-width 1600px
                        top: 161px;
                        max-height: calc(98vh - 161px);
                    }

                    @include for-screen-gte__hd { // min-width 1920px
                        top: 181px;
                        max-height: calc(98vh - 181px);
                    }

                    @include for-screen-lt__xl { // max-width 1250px
                        top: 61px;
                        max-height: calc(98vh - 61px);
                    }

                    @include for-screen-lt__md { // max-width 768px
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        max-height: 100%;
                        background-color: #FFF;
                    }
                }

                .modal-card-head,
                .modal-card-body,
                .modal-card-foot {
                    background-color: #FFF;
                    margin: -1px $grid 0;

                    @include for-screen-gte__xl { // min-width 1250px
                        margin-left: 40px;
                        margin-right: 40px;
                    }

                    @include for-screen-gte__hd { // min-width 1950px
                        margin-left: 80px;
                        margin-right: 80px;
                    }

                    @include for-screen-lt__md { // max-width 768px
                        margin: 0;
                        padding-left: $grid;
                        padding-right: $grid;
                    }
                }

                .modal-card-body {
                    p {
                        margin-top: 0;
                    }
                }


                // close button position
                .button.is-close {
                    top: $grid;
                    height: 45px;
                    width: 45px;
                    text-align: center;
                    padding: .5em;

                    @include for-screen-gte__xl { // min-width 1250px
                        right: (40px + $grid);
                    }

                    @include for-screen-gte__hd { // min-width 1950px
                        right: (80px + $grid);
                    }

                    @include for-screen-lt__xl { // max-width 1250px
                        right: (2 * $grid);
                    }

                    @include for-screen-lt__md { // max-width 768px
                        top: $grid-half;
                        right: $grid-half;
                    }

                    svg {
                        margin: 0;
                    }
                }

                .style-selector,
                .col.col-checkbox,
                .slider-input,
                .col.col-slider,
                .col.left-label,
                .col.right-label,
                .slider {
                    @include for-screen-lt__xl { // max-width 1250px
                        display: block;
                        align-items: unset;
                        justify-content: unset;
                    }
                }

                .style-selector,
                .slider {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    text-transform: uppercase;
                }

                .slider {
                    align-items: stretch;
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }

                .col {
                    align-items: center;
                    display: flex;
                    justify-content: flex-start;

                    &.col-slider {
                        flex-grow: 1;
                    }

                    &.left-label {
                        width: 29.99999%;
                        padding: $grid-half $grid;

                        span {
                            display: block;
                            text-align: right;
                            width: 100%;
                        }

                    }

                    &.right-label {
                        width: 29.99999%;
                        padding: $grid-half $grid;
                    }

                    &.slider-input {
                        width: 29.99999%;
                    }

                    &.col-reset,
                    &.col-confirm {
                        width: 49%;
                        padding: $grid 0 0;

                        .button {
                            width: auto;
                        }
                    }

                    &.col-confirm {
                        justify-content: flex-end;

                        .button {
                            color: $base-color__text_dark;

                            &:not(:first-child) {
                                margin-left: 1rem;
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                color: $base-color__text_light;
                            }
                        }
                    }
                }

                .checkbox {
                    line-height: 1;
                    white-space: nowrap;

                    span {
                        line-height: 1;
                        display: inline-block;
                    }
                }

                [type="range"] {
                    &[disabled] {
                        opacity: .3;
                    }
                }


                // delete icon only shown in small mobile (<768px)
                .col-checkbox {
                    .delete {
                        display: none;
                    }
                }

                @include for-screen-lt__xl { // max-width 1250px
                    .slider {
                        display: flex;
                        justify-content: center;
                        width: 95%;
                        min-height: 2.5rem;
                    }

                    .col {
                        &.left-label,
                        &.right-label {
                            flex: 1 1 29%;
                            margin-bottom: auto;
                            margin-top: auto;
                            padding: 0;

                            span {
                                display: block;
                                justify-content: center;
                                width: 100%;
                            }
                        }

                        &.left-label {
                            padding-right: $grid;
                        }

                        &.right-label {
                            padding-left: $grid;

                            span {
                                text-align: left;
                            }
                        }

                        &.slider-input {
                            flex: 1 1 40%;
                            width: 40%;
                        }

                        &.col-checkbox {
                            clear: both;
                            padding: .8em 0 1em;
                        }

                        &.col-reset {
                            padding-left: 0;
                        }

                        &.col-confirm {
                            justify-content: flex-end;
                            text-align: right;
                            flex-grow: 1;
                        }
                    }
                }

                @include for-screen-lt__lg { // max-width 1050px
                    .slider {
                        position: relative;
                        min-height: 3.5rem;
                        padding-top: 1rem;
                    }

                    .col {
                        &.left-label,
                        &.right-label {
                            margin-top: .3rem;
                        }

                        &.left-label {
                            padding-right: $grid-half;

                            span {
                                text-align: left;
                            }
                        }

                        &.right-label {
                            padding-left: $grid-half;

                            span {
                                text-align: right;
                            }
                        }

                        &.slider-input {
                            position: absolute;
                            top: 2px;
                            right: 0;
                            left: 0;
                            flex: 1 1 100%;
                            width: auto;
                        }

                        &.col-checkbox {
                            position: static;
                            padding-top: .3rem;
                        }
                    }
                }

                @include for-screen-lt__md { // max-width 768px
                    p.is-centered {
                        margin-bottom: ($grid * 2);
                    }

                    .style-selector {
                        flex: 1 0 auto; // for iOS
                        font-size: 88%;
                    }

                    .slider {
                        width: 90%;
                    }

                    .col {
                        &.col-checkbox {
                            .label {
                                display: none;
                            }
                        }
                    }

                    .checkbox {
                        input[type="checkbox"] {
                            top: 0;
                            left: auto;
                            right: 0;

                            &::before {
                                position: absolute;
                                top: -15px;
                                right: 0;
                                width: 40px;
                                height: 40px;
                            }

                            &:checked {
                                &::after {
                                    left: auto;
                                    top: -15px;
                                    right: 0;
                                    width: 42px;
                                    height: 42px;
                                    background-color: $base-color__bg_disabled;
                                }
                            }
                        }

                        .delete {
                            position: absolute;
                            right: 9px;
                            top: -6px;
                            display: block;
                            opacity: .7;
                        }
                    }
                }

                @include for-screen-lt__sm { // max-width 576px
                    .button-row {
                        flex-direction: column;
                    }

                    .col {
                        &.col-reset,
                        &.col-confirm {
                            width: 100%;

                            .button {
                                width: 100%;
                            }
                        }

                        &.col-reset {
                            order: 2;
                        }

                        &.col-confirm {
                            order: 1;
                        }
                    }
                }
            }
        }
    }
}
