@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/LanguageSwitcher.js
////

/// styles for LanguageSwitcher in footer menu

.language-switcher {
    $padding: 2em;
    $icon-space: 1.5em;
    $icon-space-half: calc($icon-space / 2);

    position: relative;

    select {
        @extend %text-light;

        position: relative;
        right: -$icon-space-half;
        z-index: 2;
        display: block;
        appearance: none;
        background-color: transparent;
        border: 0 none;
        text-transform: uppercase;
        padding-right: $icon-space;
    }

    svg {
        position: absolute;
        right: -$icon-space-half;
        top: .2em;
        height: .63em;
        width: auto;
        z-index: 1;
        display: inline-block;
    }
}
