.brand-post {
    @import './BrandDetail/BrandContent';

    .brand-content {
        iframe {
            width: 100%;
            height: auto;
            aspect-ratio: 16/9;
        }
    }
}
