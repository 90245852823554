////
/// @group 01: Globals
/// global elements imported in Layout.scss which are valid cross component
/// do not import the globals in standard components!!!!
////

body {
    @extend %text-light;

    font-size: 1rem;

    @include for-screen-lt__xxl {
        font-size: .88rem;
    }

    @include for-screen-lt__xl {
        font-size: 1rem;
    }
}

%h1 {
    @extend %text-hx;
    @include set-font-sizes(1.75rem, 2rem, 2.3rem, 2.8rem, 3.75rem, 4rem);
    @extend %mobile-centered;
}

%h2 {
    @extend %text-hx;
    @include set-font-sizes(1.75rem, 2rem, 2.3rem, 2.8rem, 3.75rem, 4rem);
    @extend %mobile-centered;
}

%h3 {
    @extend %text-hx;
    @include set-font-sizes(1.25rem, 1.5rem, 1.5rem, 1.5rem, 2.25rem, 2.5rem);
}

%h4 {
    @extend %text-hx;
    @include set-font-sizes(1.25rem, 1.25rem, 1.25rem, 1.25rem, 1.25rem, 1.5rem);
}

%h5 {
    @extend %text-hx;
    @include set-font-sizes(1.25rem, 1.25rem, 1.125rem, 1.125rem, 1.25rem, 1.25rem);
}

h1,
.h1,
h2.h1,
h3.h1,
h4.h1,
h5.h1,
h6.h1 {
    @extend %h1;
}

h1,
.h1,
h2.h1,
h3.h1,
h4.h1,
h5.h1,
h6.h1 {
    @extend %h1;
}

h2,
.h2,
h1.h2,
h3.h2,
h4.h2,
h5.h2,
h6.h2 {
    @extend %h2;
}

h3,
.h3,
h1.h3,
h2.h3,
h4.h3,
h5.h3,
h6.h3 {
    @extend %h3;
}

h4,
.h4,
h1.h4,
h2.h4,
h3.h4,
h5.h4,
h6.h4 {
    @extend %h4;
}

h5,
.h5,
h1.h5,
h2.h5,
h3.h5,
h3.h5,
h6 {
    @extend %h5;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    &.subtitle {
        @extend %text-thin;

        text-transform: uppercase;
    }

    a {
        @extend %text-hx;

        text-decoration: none;
    }
}

h1, .h1, .h2 {
    &.title {
        @include for-screen-gte__xl { // min-media 1250px
            margin-top: ($grid * 3);
        }

        @include for-screen-gte__hd { // min-media 1920px
            margin-top: ($grid * 6);
        }

        @include for-screen-lt__xl { // max-media 1250px
            margin-top: ($grid * 2);
        }
    }
}

.small {
    @extend %small;
}

p,
address {
    line-height: $line-height__default;
}

address {
    font-style: normal;
}

.subtitle {
    @extend %text-thin;

    color: $base-color__text_grey;
    font-size: ($font-size__hd_16 * 1.12);
    text-transform: uppercase;
}

.full-text {
    max-width: 1100px;
}

.main-content {
    ul, ol {
        margin-left: 1.25rem;
        margin-bottom: 40px;
    }

    li {
        padding-left: .5rem;
        padding-top: .3rem;

        ul, ol {
            margin-bottom: 0;
        }
    }

    @include for-screen-gte__xl {
    p.empty {

            padding-left: ($grid*2);
            padding-right: ($grid*2);
        }
    }

    .row p.empty {

            padding-left: ($grid*2 + $grid-half);
            padding-right: ($grid*2 + $grid-half);

    }

    .text-content {
        p.empty {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
