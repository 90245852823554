.product-list {
    $gap: $block-spacing;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $gap;

    @include for-screen-gte__xl {
        margin: 0 $grid*2;
    }

    .product {
        aspect-ratio: 460 / 385;

        // three columns
        width: calc((100% - 2 * #{$gap}) / 3);

        // two columns
        @include for-screen-lt__xl {
            width: calc((100% - #{$gap}) / 2);
        }

        // one column
        @include for-screen-lt__md {
            width: 100%;
        }

        // .simple-product and .blog-link are in here
    }
}
