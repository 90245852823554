.simple-product {
    background-color: $base-color__bg_grey;
    position: relative;
    width: 100%;
    height: 100%;

    .product-image {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        img {
            width: 100%;
        }

        .colors {
            display: inline;
            position: absolute;
            top: $grid;
            right: $grid;
            font-size: 78%;
        }
    }

    .product-content {
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        // we define space for the content, then let the content align themselves within
        height: 43%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 1rem;
        text-align: center;

        @include for-screen-lt__xl {
            height: 33%;
        }

        @include for-screen-lt__hd {
            height: 40%;
        }

        @include for-screen-lt__sm {
            height: 33%;
        }

        .title,
        .subtitle,
        .price {
            position: relative;
            z-index: 2;
            margin: 0 $grid;
        }

        .title {
            line-height: 1.15;  /* we switched h4 with p, match previous line height */
        }

        .subtitle {
            line-height: 140%;  /* we switched p with h3, match previous line height */
        }

        .subtitle {
            text-transform: uppercase;
            color: $base-color__text_grey;
            @extend %text-thin;
        }
    }

    .product-link {
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .fav-button {
        z-index: 4;
        position: absolute;
        bottom: 0;
        right: 0;

        border: 0 none;

        svg {
            width: 33px;
            height: 25px;
            margin: 0 auto;
            padding: 0;
        }

        .is-favorite svg {
            path {
                fill: $base-color__border_grey;
                stroke: $base-color__border_grey;
            }
        }

        .make-favorite svg {
            path {
                fill: none;
                stroke: $base-color__border_grey;
            }
        }

        @include set-colors-hover(false, transparent, false);
    }
}
