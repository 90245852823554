.style-teaser-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    gap: $grid;

    .style-teaser-item {
        // default is two columns (45% + gap, growing)
        position: relative;
        width: 45%;
        flex-grow: 1;
        aspect-ratio: 1;

        // one column, cropped images on mobile
        @include for-screen-lt__md {
            width: 90%;
            max-height: 300px;
            overflow-y: hidden;
        }


        .teaser-image {
            width: 100%;
            height: 100%;

            // small placeholder fades into real image
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;

                transition-property: opacity;
                transition-duration: 300ms;

                &.placeholder {
                    filter: blur(10px);
                    position: relative;
                    transition-timing-function: ease-out;
                }

                &.original {
                    position: absolute;
                    transition-timing-function: ease-in;
                }
            }
        }

        .teaser-content {
            position: absolute;
            text-align: center;
            width: 100%;
            left: 0;
            bottom: $grid;

            .button {
                min-width: 50%;
                max-width: 90%;
                margin: 0 auto;

                @include set-colors($base-color__text_dark, $base-color__bg_light-50, $base-color__border_dark);
                @include set-colors-hover($base-color__text_dark, $base-color__bg_light, $base-color__border_dark);
            }
        }
    }
}
