/* overpass-100 - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-100.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-100.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-100.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-200 - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-200.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-200.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-200.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-300 - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-300.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-300.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-regular - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-regular.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-regular.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-500 - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-500.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-500.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-600 - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-600.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-600.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-700 - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-700.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-700.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-800 - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-800.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-800.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-900 - latin */
@font-face {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-900.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-900.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-100-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-100-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-100-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-100-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-100-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-100-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-100-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-200-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-200-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-200-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-200-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-200-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-200-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-200-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-300-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-300-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-300-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-300-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-300-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-300-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-300-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-500-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-500-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-500-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-500-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-500-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-500-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-500-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-600-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-600-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-600-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-600-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-600-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-600-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-600-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-700-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-700-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-700-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-700-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-700-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-700-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-700-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-800-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-800-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-800-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-800-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-800-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-800-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-800-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-900-italic - latin */
@font-face {
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('~styles/fonts/Overpass/Overpass-900-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/Overpass/Overpass-900-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/Overpass/Overpass-900-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-900-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/Overpass/Overpass-900-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/Overpass/Overpass-900-italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
