.rating {
    display: flex;
    align-items: center;

    .value,
    .count {
        flex-shrink: 0;
    }

    .stars {
        $height: 1em;
        $color: #FABC05;

        display: flex;
        margin: 0 0.3rem;
        line-height: 1;

        svg {
            height: $height;
            stroke: none;
            fill: $color;
        }
    }
}
