@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/PasswordReset/PasswordReset.js
////

/// styles for password reset form

.password-reset {
    @include details-onecol-like-footercol;
}
