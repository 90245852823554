.store-selector-maximum-reached-message {
    &.overlay.modal.is-active /* beat fucking selector hell */ {
        .button-row {
            justify-content: center;

            .col-reset {
                display: none;
            }
        }
    }
}
