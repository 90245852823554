/**
 * General Layout Styles
 *
 * Meant to be reusable and minimal. May replace some of the complex mixin constructs used elsewhere
 * at some point
 */


/**
 * A list of items displayed in a three column layout on desktop, collapsing on smaller screens to
 * fewer columns
 */
.lo-itemlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: $grid 0 0 (-1 * $grid); // offset gutter width on the left

    .lo-itemlist-item {
        flex: 0 1 auto;
        padding-left: $grid; // gutter
        padding-bottom: $grid;

        width: 100%;

        @include for-screen-gte__md {
            width: 49.99999999%;
        }

        @include for-screen-gte__xl {
            width: 33.33333333%;
        }


        figure {
            img {
                width: 100%;
            }
        }
    }
}
