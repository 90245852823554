.column-list {
    list-style: none;
    padding: 0;
    margin: 0;

    columns: 2;

    @media screen and (min-width: $screen-width-sm) {
        columns: 3;
    }

    @media screen and (min-width: $screen-width-xl) {
        columns: 4;
    }

    @media screen and (min-width: $screen-width-xxl) {
        columns: 5;
    }

    li {
        padding-left: 0;
    }
}
