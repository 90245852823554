/* libre-baskerville-regular - latin */
@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('~styles/fonts/LibreBaskerville/LibreBaskerville-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-regular.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-regular.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}
/* libre-baskerville-italic - latin */
@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('~styles/fonts/LibreBaskerville/LibreBaskerville-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-italic.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-italic.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}
/* libre-baskerville-700 - latin */
@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('~styles/fonts/LibreBaskerville/LibreBaskerville-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-700.woff') format('woff'), /* Modern Browsers */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~styles/fonts/LibreBaskerville/LibreBaskerville-700.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}
