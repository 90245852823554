@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/ImageSlider.js
////

/// styles for image slider

.image-gallery {
    width: 100%;

    .image-gallery-left-nav,
    .image-gallery-right-nav {
        &::before {
            display: none;
        }
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
        top: 0;
        bottom: 0;
        transform: none;
        padding: 0 ($grid * 2);
        box-sizing: border-box;

        // background-color only for viewports > 768px
        @include for-screen-gte__md { // min-width 768px
            @include set-colors-hover($base-color__text_dark, $base-color__bg_light-50, false);
        }

        @include for-screen-lt__md { // max-width 768px
            @include set-colors-hover(false, transparent, false);

            padding-left: $grid;
            padding-right: $grid;
        }

        @include for-screen-lt__sm { // max-width 576px
            padding-left: $grid-half;
            padding-right: $grid-half;
        }
    }

    .image-gallery-bullets {
        .image-gallery-bullet{
            border-color: $base-color__border_grey;
            background-color: $base-color__bg_light-50;
            box-shadow: none;

            &.active {
                border-color: $base-color__border_dark;
                background-color: $base-color__bg_dark;
            }
        }
    }

    .image-gallery-swipe {
        position: relative;
        z-index: -1;
    }

    .image-gallery-slide-wrapper {
        > span {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
            border: 1px solid transparent;

            button {
                border: 1px solid transparent;
                top: 0;
                bottom: 0;
                height: 100%;
            }
        }
    }
}

.store-detail,
.brand-detail {
    .image-gallery-slides {
        max-height: 938px;
    }
}
