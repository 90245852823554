/**
 * Uses grid and flex layout to align the main content areas inside an item
 *
 * Item contents move around depending on premium vs. normal and desktop vs. mobile
 */
.store-list-container .store-list .store-item {
    $padding: $grid-half;

    // default alignment
    .item-content {
        height: 100%;
        display: grid;

        grid-template-columns: [left] 50% [right] 50% [end];
        grid-template-rows: [top] auto [middle] auto [bottom] auto [end];

        > .top-content {
            grid-column: left / right;
            grid-row: top / middle;
            padding: $padding $padding 0 $padding;

            display: flex;
            align-items: start;
            justify-content: space-between;

            .icon.circled-star {
                width: 23px;
                margin-left: $padding;
                flex-shrink: 0;
            }

            .icon-container {
                &.premium-plus {
                    svg g g path {
                        fill: $color_premium_plus;
                    }
                }
                &.premium {
                    svg g g path {
                        fill: $color_premium;
                    }
                }
            }
        }

        > .address-content {
            grid-column: left / right;
            grid-row: middle / end;
            padding: 0 $padding $padding $padding;
            align-self: flex-end;
        }

        > .detail-content {
            grid-column: right / end;
            grid-row: middle / end;
            padding: 0 $padding $padding $padding;
            align-self: flex-end;
        }

        // basic plus profiles have a button but no image
        > .premium-content {
            grid-column: left / right;
            grid-row: end / end;
        }

        > .function-content {
            grid-column: right / right;
            grid-row: top / middle;
            padding: $padding $padding 0 $padding;
        }
    }

    // premium alignment
    &.premium .item-content {
        grid-template-rows: [top] auto [middle] auto [center] auto [bottom] auto [end];

        > .top-content {
            grid-column: right / end;
            grid-row: top / middle;
        }

        > .address-content {
            grid-column: right / end;
            grid-row: middle / center;
            align-self: flex-end;
            padding: $padding $padding 0 $padding;
        }

        > .function-content {
            grid-column: right / end;
            grid-row: center / bottom;
            align-self: start;
            padding: calc($padding / 2) $padding 0 $padding;
        }

        > .detail-content {
            grid-column: right / end;
            grid-row: bottom / end;
            align-self: flex-end;
        }

        // (image+button)
        > .premium-content {
            grid-column: left / right;
            grid-row: top / end;

            .image-content {
                height: 100%
            }
        }
    }

    // linear flex layout on mobile
    @include for-screen-lt__md {
        .item-content,
        &.premium .item-content {
            display: flex;
            flex-direction: column;

            > .top-content,
            > .address-content,
            > .function-content,
            > .detail-content,
            > .premium-content {
                align-self: start;
                width: 100%;
            }

            > .top-content,
            > .address-content {
                padding-bottom: 0;
            }
        }
    }
}

.store-list-container .store-list.brand-context .store-item {
    &.basic {
        .item-content {
            > .top-content {
                grid-row: top / middle;
            }

            > .address-content {
                grid-row: middle / end;
                align-self: auto;
            }

            > .function-content {
                grid-row: top / middle;
                padding-bottom: 0;
            }

            > .detail-content {
                grid-row: middle / end;
                padding-top: 0;
            }
        }
    }
}

// unified store lists on brand micro sites use a more bland layout that is similar to mobile
.store-list-container.unified .store-list .store-item {
    $padding: $grid-half;

    .item-content,
    &.premium .item-content {
        display: flex;
        flex-direction: column;

        > .top-content,
        > .address-content,
        > .function-content,
        > .detail-content,
        > .premium-content {
            align-self: start;
            width: 100%;
        }

        > .top-content,
        > .address-content {
            padding-bottom: 0;
        }

        // no images, no brands
        .favorite-brands,
        .image-content {
            display: none;
        }

        .premium-content {
            order: 99; // button should be last
            flex-grow: 1; // take all remaining space of the item when neighbor is larger

            // button at the bottom
            display: flex;
            align-items: flex-end;

            .button {
                position: initial;
                margin: $padding;
            }
        }
    }
}
