////
/// @group 03: Components
/// Script Component: assets/scripts/components/Form/FieldGroup.js
////

/// styles for user profile form

.field-group {
    display: flex;

    .field {
        &:not(:first-child) {
            padding-left: 1rem;
        }

        flex: 1 1 auto;
    }
}
