@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/PremiumEyeWearStoresNearYou.js
////

/// styles for @TODO
.store-locator {

    .store-btn {
        margin: ($grid*2) 0;
    }

    .store-owner {
        margin: ($grid*4) 0 ($grid*4);
    }

    .text-content {
        a {
            font-weight: bold;
        }
    }
}
