@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/StoreDetail/StoreFeatureList.js
////

/// styles for store feature list

.store-feature-list {
    display: flex;
    flex-flow: row wrap;

    .store-feature-entry {
        display: flex;
        flex: 0 0 33.32%;
        flex-flow: row nowrap;
        place-content: flex-start;
        align-items: flex-start;
        color: $base-color__text_dark;
        padding-top: .3em;
        padding-bottom: .3em;

        @include for-screen-lt__xl {
            flex: 0 0 49.999%;
            padding-left: $grid;
        }

        @include for-screen-lt__md {
            flex: 0 0 100%;
        }

        .item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-grow: 1;
            flex-shrink: 0;
            flex-flow: column wrap;
            max-width: 90%;
        }
    }
}
