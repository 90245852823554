@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/ProductDetail/ProductDetail.js
////

/// styles for product-detail

.product-detail {
    .detail-header {
        // brand name
        .title {
            text-transform: none;
            margin-bottom: .2em;
            font-size: 26px;
            font-weight: bold;

            div {
                display: inline;
            }
        }

        .header-buttons {
            display: none;
        }
    }

    .product-headline {
        display: flex;
        align-items: center;

        // product name
        h1 {
            font-weight: bolder;
            font-size: 22px;

            .variant {
                text-transform: none;
            }
        }

        // favorite button
        button {
            border: 0 none;
            font-size: inherit;
            line-height: inherit;
            padding: 0;
            margin: 0 0 0 1rem;
            background: transparent;
            color: $base-color__text_grey;
        }

        svg {
            display: inline-block;
            padding: 0;
            margin: 0;
        }

        .is-favorite {
            svg {
                path {
                    fill: $base-color__text_lightgrey;
                    stroke: $base-color__text_lightgrey;
                }
            }
        }
    }

    .subtitle {
        margin-top: 0;
        margin-bottom: 0;
        color:$base-color__text_grey;
    }

    .product-content {
        display: flex;
        flex-direction: row-reverse;
        gap: $grid;
        margin-top: $grid;

        @include for-screen-lt__xl {
            flex-direction: column;
        }
    }

    .product-stage {
        width: 66%;
        overflow: hidden;
        position: relative;

        @include for-screen-lt__xl {
            width: 100%;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $grid;

        > .controls {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            $space: 0.25rem;

            button {
                padding: 0;
                display: flex;
                align-items: center;

                span {
                    padding: 0 1rem;
                }

                &:disabled {
                    opacity: 0.3;
                    cursor: default;
                }

                .thumbnails {
                    display: flex;
                    gap: $space;
                    margin: $space 0 $space $space;
                }
            }

            &.try-on-controls {
                justify-content: space-between;

                .mobile {
                    display: none;
                }

                @include for-screen-lt__md {
                    .mobile {
                        display: initial;
                    }

                    .desktop {
                        display: none;
                    }
                }
            }

            .corona {
                border: 1px solid transparent;

                button {
                    height: 100%;
                }
            }

            .model-button-group {
                display: flex;
                gap: $space;

                .selected {
                    border-color: black;

                    button {
                        border-color: black;
                    }
                }
            }
        }

        .try-on {
            position: relative;
            display: flex;
            width: 100%;
            height: auto;
            aspect-ratio: 3 / 2;
            background-color: $base-color__bg_grey;
            justify-content: center;

            .retake-button {
                font-size: 90%;
                position: absolute;
                bottom: $grid-half;
                background-color: white;

                &:hover {
                    color: white;
                    background-color: black;
                }
            }
        }

        #ditto-try-on {
            padding: 0;
            width: 100%;
            height: 100%;

            $ditto-breakpoint: 640px;

            @media only screen and (max-width: $ditto-breakpoint) {
                width: auto;
                aspect-ratio: 1 / 1;
            }

            @include for-screen-gte__hd {
                width: auto;

                iframe {
                    width: 1024px;
                }
            }
        }

        #ditto-try-on-creator {
            display: flex;
            height: 600px;
            padding: 0;
            margin-bottom: -$grid;
        }
    }

    .selection-image {
        position: relative;
        width: 100%;

        .magnifier {
            padding: 0.5rem;
            cursor: pointer;
            z-index: 11;
            position: absolute;
            bottom: 0;
            right: 0;
            line-height: 1;
        }

        &.magnified {
            cursor: zoom-in;

            .magnifier {
                background-color: $base-color__bg_dark;
                svg.icon path {
                    stroke: $base-color__border_lightgrey
                }
            }

            .image-gallery-slide-wrapper {
                > span {
                    display: none;
                }

                .image-gallery-swipe {
                    z-index: 0;
                }
            }
        }
    }

    .selection-content {
        width: 33%;
        overflow: hidden;

        @include for-screen-lt__xl {
            width: 100%;
        }
    }

    .selection-description {
        text-align: center;
        width: 80%;
        margin: 0 auto ($grid * 4);
        padding-top: ($grid * 4);

        @include for-screen-lt__hd {
            width: 100%;
            margin: 0 auto ($grid * 2);
            padding-top: ($grid * 2);
        }

        @include for-screen-lt__xl {
            text-align: left;
        }

        a {
            font-weight: bold;
        }
    }

    .variants {
        p {
            margin-top: 0;
        }

        ul {
            margin-top: 0;
            margin-left: 0;
            list-style-type: none;
            display: flex;
            flex: 1 1 0;
            flex-flow: row wrap;
            place-content: flex-start;
            align-items: flex-start;

            li {
                cursor: pointer;
                max-width: 20%;
                padding-left: 0;
                padding-right: 5%;
            }

            a {
                text-decoration: none;
            }

            img {
                border: 1px solid transparent;
                width: 120px;
                max-width: 100%;
            }

            p {
                padding: 0;
                margin: 0;
                text-align: center;

                span {
                    line-height: 1;
                }

                .color-name {
                    display: block;
                    text-align: center;
                    padding-bottom: $element-spacing-em;
                    font-size: 88%;
                }
            }

            .active {
                img {
                    border-color: grey;
                }
            }
        }
    }

    .selection-image,
    .variants {
        img {
            height: auto;
            aspect-ratio: 3 / 2;
            object-fit: cover;
        }
    }

    .properties {
        dl {
            display: block;
            width: 100%;

            dt {
                float: left;
                clear: left;
                margin-bottom: 2px;
                margin-right: .4rem;

                &::after {
                    content: ":"
                }
            }

            dd {
                width: 100%;
                margin-bottom: 2px;
                &::after {
                    @include clearfix;
                }
            }

            .genders,
            .genders + dd,
            .width,
            .width + dd,
            .prices,
            .prices + dd {
                padding-top: $element-spacing-em;
            }

            .prices {
                @import './PriceLabel';

                .to {
                    display: inline-block;
                    margin: 0 .4rem;
                }
            }
        }

        .offers {
            display: none;
        }

        .contact-store-button {
            clear: both;
            margin-top: ($element-spacing-em * 2);

            .coming-soon {
                opacity: 0.3;
                cursor: default;
            }
        }

        .hosted-by {
            margin: 0;
            font-size: 90%;
            opacity: 0.7;
        }
    }

    //  mobile view
    @include for-screen-lt__xl {
        .title,
        .subtitle {
            text-align: left;
        }
    }
}
