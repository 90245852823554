@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/Footer.js
////

/// styles for footer menu and copyright line
.footer {
    clear: both;

    @include container;

    margin: ($grid*2) 0 ($grid*2);

    .menu {
        display: flex;
        flex: 1 1 0%;
        flex-flow: row wrap;
        place-content: stretch flex-start;
        align-items: stretch;
    }

    .menu-item {
        align-items: center;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: ($grid * 3);
        margin: -.5px;
        text-align: center;
        text-decoration: none;
        border: 1px solid;
        text-transform: uppercase;

        @extend %text-light;

        @include for-screen-gte__hd {
            font-size: $font-size__hd_20;
        }

        @include set-colors($base-color__text_grey, $base-color__bg_light, $base-color__border_grey);
        @include set-colors-hover($base-color__text_dark, $base-color__bg_grey, false);

        @include for-screen-gte__lg {
            width: calc(33.333333% + 1px); // - .5px left and right margin of menu-item  (.5 + .5 = 1)
        }

        @include for-screen-lt__lg {
            width: 49.999999%;
        }


        @include for-screen-lt__md {
            width: 100%;
        }
    }

    .to-top {

        text-align: center;
        margin: $grid 0 $grid;

        a {
            @include arrow-up-link;
        }
    }
}
