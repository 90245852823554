////
/// @group 00: Utils
/// has to be included and is usable in every component
////

@mixin max-media($width) {
    $max-width: ($width - 1);

    @media only screen and (max-width: ($max-width)) {
        @content;
    }
}

@mixin min-media($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

@mixin for-screen-gte__hd {
    @include min-media($screen-width-hd) {
        @content;
    }
}

@mixin for-screen-lt__hd {
    @include max-media($screen-width-hd) {
        @content;
    }
}

@mixin for-screen-lt__xxl {
    @include max-media($screen-width-xxl) {
        @content;
    }
}

@mixin for-screen-gte__filter-breakpoint {
    @include min-media($screen-width-for-filter-lg) {
        @content;
    }
}

@mixin for-screen-lt__filter-breakpoint {
    @include max-media($screen-width-for-filter-lg) {
        @content;
    }
}

@mixin for-screen-gte__xxl {
    @include min-media($screen-width-xxl) {
        @content;
    }
}

@mixin for-screen-lt__xl {
    @include max-media($screen-width-xl) {
        @content;
    }
}

@mixin for-screen-gte__xl {
    @include min-media($screen-width-xl) {
        @content;
    }
}

@mixin for-screen-lt__lg {
    @include max-media($screen-width-lg) {
        @content;
    }
}

@mixin for-screen-gte__lg {
    @include min-media($screen-width-lg) {
        @content;
    }
}

@mixin for-screen-lt__md {
    @include max-media($screen-width-md) {
        @content;
    }
}

@mixin for-screen-gte__md {
    @include min-media($screen-width-md) {
        @content;
    }
}

@mixin for-screen-lt__sm {
    @include max-media($screen-width-sm) {
        @content;
    }
}

@mixin for-screen-gte_sm {
    @include min-media($screen-width-sm) {
        @content;
    }
}

/// mixin for container
@mixin container {
    box-sizing: border-box;
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;
    max-width: $container-max-width-hd;

    @include for-screen-lt__hd {
        max-width: $container-max-width-xxl;
    }

    @include for-screen-lt__xxl {
        max-width: $container-max-width-xl;
    }

    @include for-screen-lt__xl {
        max-width: $container-max-width-lg;
    }

}

/// mixin for section
@mixin section {
    box-sizing: border-box;

    @include for-screen-lt__xl {
        padding: 0;
        margin: 0 0 $grid-half;
    }

    @include for-screen-gte__xl {
        padding: 0 ($grid*2);
        margin: $grid-half 0;
    }

    @include for-screen-gte__hd {
        padding: 0 ($grid*4);
        margin: ($grid*2) 0;
    }
}

@mixin main-content {
    box-sizing: border-box;
    width: auto;
    padding: 0 $grid;

    @include for-screen-gte__xl {
        padding: 0;
    }

}

/// mixin for content that has default left and right spacing in desktop and mobile
@mixin text-content {
    box-sizing: border-box;
    width: auto;

    @include for-screen-gte__xl {
        padding: 0 $grid*2;
    }

}

/// mixin for content that has default left and right spacing in desktop and mobile and a border
@mixin border-content {
    @include text-content;
    border: 1px solid $base-color__border;
}

/// mixin for content that has forms in it
@mixin form-content {
    @include text-content;

    form {
        min-width: 280px;
        width: 100%;
        max-width: $form-max-width;
    }
}

@mixin items-in-row-content ($itemName, $padding: $grid) {
    .row {
        margin: (-1 * $padding);
        @content;

        #{$itemName} {
            padding: $padding;
        }
    }
}

/// mixin for formatting form with 3 cols, 2 cols, 1 col (like footer cols)
/// basics for all
@mixin details-like-footercol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .details {
        display: flex;
        justify-content: space-between;
        margin-bottom: $grid;

        @include for-screen-lt__md {
            flex-direction: column;
        }

        .control {
            padding-right: $grid;

            @include for-screen-lt__xl {
                padding-right: 0;
            }
        }
    }
}

/// 1 col
@mixin details-onecol-like-footercol {
    @include details-like-footercol;

    .details {
        .details-left {
            display: flex;
            flex-direction: column;

            @include for-screen-gte__md { // min-width 768px
                flex: 0 0 auto;
                width: calc(50% - #{$grid});
            }

            @include for-screen-gte__xxl { // min-width 1600px
                width: calc(33.333333% + 7px);
            }

            .control {
                @include for-screen-lt__xxl { // max-width 1600px
                    padding-right: 0;
                }
            }
        }
    }
}

/// 2 col with space between like width of center col
@mixin details-twocol-like-footercol {
    @include details-onecol-like-footercol;

    .details {
        .details-right {
            display: flex;
            flex-direction: column;

            @include for-screen-gte__md { // min-width 768px
                flex: 0 0 auto;
                width: calc(50% - #{$grid});
            }

            @include for-screen-gte__xxl { // min-width 1600px
                width: calc(33.333333% + 27px);
                padding-left: ($grid * 2);
            }
        }
    }
}

/// 3 col
@mixin details-threecol-like-footercol {
    @include details-twocol-like-footercol;

    .details {
        .details-left {
            @include for-screen-gte__md { // min-width 768px
                width: calc(33.333333% + 1px);
            }

            @include for-screen-gte__xl { // min-width 1250px
                width: calc(33.333333% + 7px);
            }

            .control {
                @include for-screen-gte__md { // min-width 768px
                    padding-right: 0;
                }

                @include for-screen-gte__xl { // min-width 1250px
                    padding-right: $grid;
                }

                @include for-screen-lt__md { // max-width 768px
                    padding-right: 0;
                }
            }
        }

        .details-right {
            @include for-screen-gte__md { // min-width 768px
                width: calc(33.333333% + 1px);
            }

            @include for-screen-gte__xl { // min-width 1250px
                width: calc(33.333333% + 27px);
                padding-left: ($grid * 2);
            }

            @include for-screen-lt__md { // max-width 768px
                padding-left: 0;
            }
        }

        .details-center {
            display: flex;
            flex-direction: column;

            @include for-screen-gte__md { // min-width 768px
                width: 28.5%;
            }

            @include for-screen-gte__xl { // min-width 1250px
                flex: 1 0 auto;
                width: 30%;
                padding-left: $grid;
            }
        }
    }
}

/// 2 col no center col
@mixin details-twocol-halfsize {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-screen-gte__lg {
        .details {
            display: flex;
            justify-content: space-between;

            .details-left,
            .details-right {
                display: flex;
                flex-direction: column;
                width: 50%;
            }

            .details-left {
                padding-right: $grid;
            }

            .details-right {
                padding-top: 1.75rem;
                padding-left: $grid;
            }
        }
    }

    @content;
}
