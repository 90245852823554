////
/// @group 01: Globals
/// global elements imported in Layout.scss which are valid cross component
/// do not import the globals in standard components!!!!
////
@import '../_settings/_utils_el_link';

%text-link {
    @include text-link;
}

a {
    @extend %text-link;
}
