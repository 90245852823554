@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/UserProfile/PasswordChangeForm.js
////

/// styles for user profile password-change-form

#password-change-form {
    @include details-onecol-like-footercol;
}
