.brand-posts {
    .brand-post-list {
        list-style: none;
        margin-left: 0;

        .brand-post-entry {
            padding: 0;

            h4 {
                font-weight: bold;
            }
        }
    }
}
