.plan-table {
    $number-of-plans: 4; // needs to be adjusted if number of plans changes
    margin-top: $grid * 2;
    width: 100%;

    table {
        /** General layout **/
        position: relative;
        width: 100%;
        min-width: 600px;
        table-layout: fixed;
        border-collapse: collapse;

        @include for-screen-lt__hd {
            button,
            .button {
                white-space: break-spaces;
            }
        }

        td, th {
            width: calc(100% / ($number-of-plans + 1));
            padding: $grid-half;
            text-align: center;

            @include for-screen-lt__xl {
                hyphens: auto;
            }
        }

        td.description {
            text-align: left;
        }

        tr:nth-child(even) {
            th,
            td {
                background-color: $base-color__bg_grey;
            }
        }

        svg {
            height: 1.2em;
            width: 1.2em;
            vertical-align: center;

            &.close {
                path {
                    stroke-width: 4px;
                }
            }
        }

        /** current plan styling **/
        tbody {
            tr:first-child {
                td {
                    padding-top: 2em;
                }

                td.current {
                    border-top: 1px solid $base-color__border_grey;
                }
            }

            tr:last-child {
                td {
                    padding-bottom: 2em;
                }

                td.current {
                    border-bottom: 1px solid $base-color__border_grey;
                }
            }

            td.current {
                border-right: 1px solid $base-color__border_grey;
                border-left: 1px solid $base-color__border_grey;
            }
        }

        thead {
            svg {
                background: $base-color__bg;
                height: 2em;
                width: 2em;
                padding: 0 1em;
                margin-bottom: -2em;
            }
        }

        /* individual row styling */

        tr.plan td {
            vertical-align: top;

            svg {
                height: 2em;
                width: 2em;
            }

            p {
                text-transform: uppercase;
                font-family: $font-family_serif;
                font-size: 130%;
            }

            &.description {
                font-weight: bold;
            }

            &.basic {
                svg {
                    visibility: hidden;
                }
            }

            &.premium {
                svg path {
                    fill: $color_premium;
                    stroke: $color_premium;
                }
            }

            &.premium-plus {
                svg path {
                    fill: $color_premium_plus;
                    stroke: $color_premium_plus;
                }
            }

            &.premium-pro {
                svg path {
                    fill: $color_premium_pro;
                    stroke: $color_premium_pro;
                }
            }
        }

        tr.bold td {
            &.description {
                font-weight: bold;
            }
        }

        tr.price td {
            vertical-align: top;
            p {
                font-size: 150%;
                font-weight: bold;
                margin-bottom: $grid-half;
            }
        }

        tr.description td,
        tr.suitability td {
            vertical-align: top;
        }

        tr.card td {
            &.premium {
                svg g g path {
                    fill: $color_premium;
                    stroke: $color_premium;
                }
            }

            &.premium-plus {
                svg g g path {
                    fill: $color_premium_plus;
                    stroke: $color_premium_plus;
                }
            }

            &.premium-pro {
                svg g g path {
                    fill: $color_premium_pro;
                    stroke: $color_premium_pro;
                }
            }
        }
    }
}
