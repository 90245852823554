body.modal-open {
    position: fixed;
}

.ditto-container {
    display: flex;
    justify-content: center;
}

#ditto-try-on, #ditto-try-on-creator {
    width: 100%;
    height: 100%;
    padding-bottom: 3rem;
}

#creation {
    height: 600px;
}

#virtual-try-on-modal {
    .modal-card {
        width: 100%;
    }
}

#ditto {
    /* Size of the viewer can be changed. */
    width: 600px;
    height: 100%;
}

.try-on-modal {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: white;

    .try-on-wrapper {
        width: 100%;
        max-width: 1024px;
        margin-top: 5rem;

        .try-on {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .retake-button {
                margin-bottom: 8rem;
            }
        }
    }

    .button.is-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;

        path {
            stroke-width: 12px;
        }
    }
}
