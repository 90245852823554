@import '../../_settings/utils';

@mixin store-map-size($navbar-height, $navbar-space: 0, $space-count: 2) {
    $navbar-total-height: $navbar-height + $navbar-space * $space-count;
    top: $navbar-total-height + $grid;
    height: calc(100vh - #{$navbar-total-height + $grid * 2});
}

.store-list-container {
    .map-container {
        @include for-screen-lt__md {
            position: relative;
            height: 66vh;
            width: 100%;
            margin: $grid-half 0;
        }

        @include for-screen-gte__md {
            position: sticky;
            margin-left: $grid-half;
            width: 50%;

            /* lt xl */
            @include store-map-size($navbar-height-xl);
        }

        @include for-screen-gte__xl {
            @include store-map-size($navbar-height-xl, $navbar-space-xl);
        }

        @include for-screen-gte__xxl {
            @include store-map-size($navbar-height-xxl, $navbar-space-xl);
        }

        @include for-screen-gte__hd {
            @include store-map-size($navbar-height-hd, $navbar-space-hd, 1);
        }

        .map {
            height: 100%;

            .marker {
                display: flex;

                .image-container {
                    width: 96px;
                    height: 80px;
                    margin-right: $grid-half;
                    overflow: hidden;
                    position: relative;

                    img,
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        height: 100%;
                        transform: translate(-50%, -50%);
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;

                    .name {
                        margin: 0 0 .3rem;
                        font-weight: bold;
                    }

                    address {
                        font-family: $font-family_sans;
                        font-weight: 400;
                    }

                    a {
                        padding-top: .3rem;
                    }
                }
            }
        }

        .marker-list {
            display: none;
        }

        .search-area {
            position: absolute;
            top: $grid-half;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 80%;

            background: white;

            &:hover,
            &:focus {
                background: black;
            }
        }
    }
}

.product-filters-sticky {
    .store-list-container {
        .map-container {
            $sticky-filters-height: 40px;

            @include for-screen-gte__filter-breakpoint {
                @include store-map-size($navbar-height-xl + $sticky-filters-height);
            }

            @include for-screen-gte__xl {
                @include store-map-size($navbar-height-xl + $sticky-filters-height, $navbar-space-xl);
            }

            @include for-screen-gte__xxl {
                @include store-map-size($navbar-height-xxl + $sticky-filters-height, $navbar-space-xl);
            }

            @include for-screen-gte__hd {
                @include store-map-size($navbar-height-hd + $sticky-filters-height, $navbar-space-hd, 1);
            }
        }
    }
}

.brand-micro-site {
    .store-list-container {
        .map-container {
            /* there is no navbar */
            @include store-map-size(0);
        }
    }
}
