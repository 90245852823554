.store-list-wrapper {
    .details {
        .details-left {
            justify-content: center;

            .info {
                margin: 0;
            }
        }

        .details-right {
            padding-top: 0;
        }
    }

    .store-list-container {
        margin-top: $grid;
    }
}
