.promotion-code {
    $icon-size: 20px;
    $icon-right: $grid-half;

    .input-group {
        display: flex;
        gap: $grid-half;
    }

    .field {
        width: 20rem;
        margin-bottom: 0;
    }

    input,
    button {
        height: 3rem;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    }

    .control {
        position: relative;

        .icon {
            position: absolute;
            top: 50%;
            right: $icon-right;
            transform: translate(0, -60%);

            width: $icon-size;
            height: $icon-size;
        }
    }
}
