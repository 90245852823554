/**
 * Styles the actual item contents in regards to design
 */
.store-list-container .store-list .store-item {
    @extend %text-light;

    // used in contact tool
    &.own-store,
    &.already-contacted {
        opacity: 0.3;
        cursor: default;
    }

    // general content styling
    .store-item-content {
        border: 1px solid transparent;
        background: $base-color__bg_grey;
        position: relative;

        // the whole item may be covered by an anchor - don't hover its style
        > a {
            text-decoration: none;
        }

        // used in contact tool
        label.checkbox {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            width: 16px;
            height: 16px;
            padding-left: 0;

            input {
                cursor: pointer;
            }
        }

        // applied when hovering over the map item or the item itself
        &.highlighted {
            border-color: black;
        }
    }

    .address-content,
    .detail-content {
        > address,
        > div {
            margin-top: calc($grid / 4); // space to previous item
            font-size: 90%;
        }
    }

    .top-content {
        .title {
            // override standard headline margin
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .detail-content {

        .distance {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include for-screen-lt__md {
                font-size: .75rem;
            }
        }

        .favorite-brands {
            margin-bottom: $grid-half;
        }

        @import '../Rating';
    }

    .premium-content {
        overflow: hidden;
        position: relative;

        img, svg {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            aspect-ratio: 1.3;
        }

        .button {
            position: absolute;
            right: $grid-half;
            bottom: $grid-half;

            @include set-colors($base-color__text_dark, $base-color__bg_light-50, $base-color__border_dark);
            @include set-colors-hover($base-color__text_dark, $base-color__bg_light, $base-color__border_dark);
        }
    }

    // basic entries with premium content have a button. it should not be positioned absolute
    &.basic .premium-content .button {
        position: static;
        margin-left: $grid-half;
        margin-bottom: $grid-half;
    }

    .function-content {
        .func-btn {
            border: none;
            font-size: 90%;
            font-weight: normal;
            white-space: nowrap;
            text-transform: uppercase;
            padding: 0;

            margin-right: $grid-half;

            &:last-child {
                margin-right: 0;
            }

            svg {
                width: 1rem;
                margin-right: 0.3rem;
            }

            &:focus,
            &:hover {
                color: black;
                background: transparent;
                text-decoration: underline;
            }

            &.appointment {
                svg {
                    width: auto;
                    height: 0.75rem;
                }
            }
        }
    }

    .address-content {
        .open {
            display: flex;
            align-items: center;
            gap: 0.2rem;

            svg {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-top: -3px;

                path {
                    fill: $base-color__text_grey;
                    stroke: $base-color__text_grey;
                }
            }

            .label {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}




.store-contact-modal {
    &.overlay.modal.is-active /* beat fucking selector hell */ {
        .modal-card {
            width: auto;
        }
    }
}

// unified store lists on brand micro sites use slightly different styling
.store-list-container.unified .store-list .store-item {
     .premium-content {
         .button {
             @include set-colors($base-color__text_dark, transparent, $base-color__border_dark);
             @include set-colors-hover($base-color__text_light, $base-color__bg_dark, $base-color__border_dark);
         }
     }
}
