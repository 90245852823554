.link-generator {
    width: 100%;
    margin-top: $grid;
    margin-bottom: $grid;
    padding: $grid;
    background-color: $base-color__bg_greyish;

    label {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
            width: 10em;
        }
        input#link-generator-url {
            flex-grow: 1;
        }
    }
}

.product-filters-sticky .link-generator {
    display: none;
}
