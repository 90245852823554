/**
 * The main SCSS file including everything else
 */

/* + + +  utils start  + + + */

// global vars and mixins that can be used in every component
// do not add global classes and styles that would break the encapsulation of components
@import './_settings/_utils_vars';
@import './_settings/_utils_grid';
@import './_settings/_utils_helper';
@import './_settings/_utils_modal';
@import './_settings/_utils_el_button';
@import './_settings/_utils_el_link';

/* + + +  utils end  + + + */


/* + + +  normalize start  + + + */

// contains generic styles for the web document and provides cross-browser consistency
@import './_settings/normalize';
@include normalize();

/* + + +  normalize end  + + + */


/* + + +  globals start  + + + */

// global elements imported in Layout.scss which are valid cross component
// do not import the globals in standard components!!!!
@import './_settings/_el_form-elements';
@import './_settings/_el_button';
@import './_settings/_el_link';
@import './_settings/_el_text';
@import './_settings/_el_inlinesvg';


.a11y-hidden {
    @extend %a11y-hidden;
}

.a11y-focusable {
    @extend %a11y-focusable;
}
.is-centered {
    text-align: center;
}

.is-margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.hidden {
    display: none !important;
}

/* + + +  globals end  + + + */

@import './_settings/layout';

@import './fonts/Overpass';
@import './fonts/LibreBaskerville';
@import '~material-design-icons/iconfont/material-icons.css';

/* + + +  components start  + + + */

// structure
@import './components/Layout';
@import './components/Footer';
@import './components/Divider';
@import './components/NavBar/NavBar';

@import './components/LanguageChanger';
@import './components/LanguageSwitcher';
@import './components/BaseDetail';
@import './components/CookieBanner';
@import './components/TrackingMessage';

@import './components/PremiumEyeWareStoresNearYou';
@import './components/CuratedStores';
@import './components/EyewearFinder';
@import './components/StyleTeaser';
@import './components/ColumnList';

@import './components/FAQ';

// image slider
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import './components/ImageSlider';

// blog
@import './components/BlogDetail/BlogDetail';
@import './components/BlogDetail/BlogNavigation';
@import './components/BlogList/BlogList';

// brands
@import './components/BrandDetail/BrandDetail';
@import './components/BrandList/BrandList';
@import './components/BrandPost';

// content post
@import './components/ContentPost/ContentPost';

// country list
@import './components/CountryList';

// product
@import './components/ProductDetail/ProductDetail';
@import './components/ProductDetail/SimilarProducts/SimilarProducts';
@import './components/ProductFilters/ProductFilters';
@import './components/ProductFilters/LinkGenerator';
@import './components/ProductList/ProductList';
@import './components/ProductList/SimpleProduct';
@import './components/ProductList/BlogLink';
@import './components/ProductRequest';

// virtual try on
@import './components/VirtualTryOn/VirtualTryOn.scss';

// store
@import './components/PlanTable';
@import './components/BraintreeDropIn';
@import './components/Payment/Payment';
@import './components/StoreDetail/StoreDetail';
@import './components/StoreDetail/StoreFeatureList';
@import './components/StoreLocator';
@import './components/StoreSelector';
@import './components/StoreRegister/Register';
@import './components/StoreLinks/StoreLinks';

// store search
@import './components/BrandCitySearch/BrandCitySearch';
@import './components/BrandCitySearch/BrandCitySearchModal';

// forms
@import './components/Form/FieldGroup';
@import './components/PasswordReset/PasswordReset';
@import './components/UserProfile/EmailChangeForm';
@import './components/StoreContactForm/StoreContactForm';
@import './components/UserProfile/PasswordChangeForm';

// Google Maps
@import './components/GoogleMap/GoogleMap';

// Messages
@import './components/Messages/Messages';
@import './components/ErrorMessage';

// Modal
@import './components/Overlay';
@import './components/Modal/Modal';

/* + + +  components end  + + + */

/* + + +  page layouts start  + + + */

@import './pages/brand-list';
@import './pages/login';
@import './pages/registration';
@import './pages/start';

/* + + +  pages layouts end  + + + */
