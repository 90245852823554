.more-stores-hint {
    .col-reset {
        .button {
            @include set-colors($base-color__text_light, $base-color__bg_dark, $base-color__border);
            @include set-colors-hover($base-color__text_dark,$base-color__bg_light, $base-color__border_dark);
        }
    }

    &.overlay.modal.is-active /* selector hell */ {
        .modal-card-foot {
            .button-row {
                justify-content: space-evenly;
                flex-wrap: wrap;

                .col {
                    margin-top: 1rem;
                }
            }
        }

        @include for-screen-gte__md {
            .modal-card {
                width: 80vw;
                max-width: 800px;
            }
        }

        @include for-screen-gte__xl {
            .modal-card {
                width: 900px;
                max-width: none;
            }
        }
    }
}
