@import '../../_settings/utils';

/**
 * Defines the overall layouting of the store locator (and map view) and the item
 * widths to use
 */
.store-list-container {
    margin: ($grid * 2) 0;
    display: flex;
    gap: $grid-half;

    @include for-screen-lt__md {
        flex-direction: column-reverse;
    }

    .store-list,
    .map-container {
        @include for-screen-lt__md {
            width: 100%;
            flex-direction: column-reverse;
        }

        @include for-screen-gte__md {
            width: 50%;
        }
    }

    .store-list {
        .row {
            display: flex;
            flex-direction: column;
            row-gap: $grid;
            // FAKEGAP: we can't use column- gap, because it does not work properly with percentage widths
            // see https://stackoverflow.com/q/66240486

            // default width is 100% for all items
            .store-item {
                width: 100%;

                .store-item-content {
                    height: 100%;
                }
            }
        }
    }

    // curated store list shows manually selected premium stores on start page
    // unified store list is used on brand microsites
    &.unified .store-list,
    &.curated .store-list {
        .row {
            flex-direction: row;
            flex-wrap: wrap;
        }

        // item widths only half width
        .store-item {
            width: 49.99999999%;

            // FAKEGAP
            &:nth-of-type(odd) {
                padding-right: $grid;
            }
        }

        // full width on small screens
        @include for-screen-lt__md {
            .store-item {
                width: 100%;
            }
        }
    }

    &.curated .store-list {
        width: 100%;
    }

    /* load more button */
    .section {
        margin: $grid 0 0 0;
    }
}
