////
/// @group 00: Utils
/// has to be included and is usable in every component
////

/// set color, background color and border color for an element
/// set value you do not want to set to false or leave it
/// @param {Color} $color [false] - text color
/// @param {Color} $bg-color [false] - background color
/// @param {Color} $border-color [false] - border color
/// @output color styles
@mixin set-colors($color: false, $bg-color: false, $border-color: false) {
    @if $color {
        color: $color;
    }

    @if $bg-color {
        background-color: $bg-color;
    }

    @if $border-color {
        border-color: $border-color;
    }

    @content;
}

/// set color, background color and border color for the hover focus and active state
/// set value you do not want to set to false or leave it
/// @param {Color} $color [false] - text color
/// @param {Color} $bg-color [false] - background color
/// @param {Color} $border-color [false] - border color
/// @output hover, focus and active states with color styles
@mixin set-colors-hover($color: false, $bg-color: false, $border-color: false) {
    &:hover,
    &:focus,
    &:active {
        @if $color {
            color: $color;
        }
        @if $bg-color {
            background-color: $bg-color;
        }
        @if $border-color {
            border-color: $border-color;
        }

        @content;
    }
}

/// define animation for color, background color and border color
/// set value you do not want to set to false or leave it
/// @param {String} $animation-name - name of the animation
/// @param {Color} $bg-start [false] - background color for 0% animation duration state
/// @param {Color} $bg-end [false] - background color for 100% animation duration state
/// @param {Color} $border-color-start [false] - border color for 0% animation duration state
/// @param {Color} $border-color-end [false] - border color for 100% animation duration state
/// @param {Color} $color-start [false] - text color for 0% animation duration state
/// @param {Color} $color-end [false] - text color for 100% animation duration state
@mixin define-color-flash-animation ($animation-name, $bg-start: false, $bg-end: false, $border-color-start: false, $border-color-end: false, $color-start: false, $color-end: false) {
    @-webkit-keyframes #{$animation-name} {
        0% {
            @if $bg-start {
                background-color: $bg-start;
            }

            @if $border-color-start {
                border-color:  $border-color-start;
            }

            @if $color-start {
                color:  $color-start;
            }
        }
        100% {
            @if $bg-end {
                background-color: $bg-end;
            }

            @if $border-color-end {
                border-color:  $border-color-end;
            }

            @if $color-end {
                color:  $color-end;
            }
        }
    }
}

/// use an animation
/// @param {String} $animation-name - name of the animation
/// @param {time} $animation-duration [4s] - time used for animation
/// @param {Integer|String} $animation-duration-count [1] - repetitions of the animation or use infinite
@mixin use-animation($animation-name, $animation-duration: 4s, $animation-duration-count: 1) {
    animation: $animation-name $animation-duration $animation-duration-count;
}

@mixin set-font-sizes($font-sm: false, $font-md: false, $font-lg: false, $font-xl: false, $font-xxl: false, $font-hd: false) {
    @if $font-sm {
        font-size: $font-sm;
    }

    @if $font-md {
        @include for-screen-gte__md {
            font-size: $font-md;
        }
    }

    @if $font-lg {
        @include for-screen-gte__lg {
            font-size: $font-lg;
        }
    }

    @if $font-xl {
        @include for-screen-gte__xl {
            font-size: $font-xl;
        }
    }

    @if $font-xxl {
        @include for-screen-gte__xxl {
            font-size: $font-xxl;
        }
    }

    @if $font-hd {
        @include for-screen-gte__hd {
            font-size: $font-hd;
        }
    }
}

%a11y-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

%a11y-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}

%mobile-centered {
    @include for-screen-lt__xl {
        text-align: center;
    }
}

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

%text-hx {
    font-family: $font-family_serif;
    font-weight: 400;
    font-style: normal;
}

%text-default {
    font-family: $font-family_sans;
    font-weight: 500;
    font-style: normal;
}

%text-light {
    font-family: $font-family_sans;
    font-weight: 400;
    font-style: normal;
}

%text-thin {
    font-family: $font-family_sans;
    font-weight: 200;
    font-style: normal;
}

%flowing-text {
    line-height: 137%;
}

%small {
    color: $base-color__text_dark;
    font-size: $font-size__hd_12;
}
