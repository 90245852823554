////
/// @group 00: Utils
/// has to be included and is usable in every component
////

/// default button
/// @param {Color} $color [$base-color__text] - text color
/// @param {Color} $bg-color [transparent] - background color
/// @param {Color} $border-color [$base-color__border] - border color
/// @output complete btn styling
@mixin base-btn($color: $base-color__text, $bg-color: transparent, $border-color: $base-color__border) {
    @extend %text-default;

    transition: $transition all;
    cursor: pointer;
    position: relative;
    display: inline-block;
    box-shadow: none;
    box-sizing: border-box;
    justify-content: center;
    background-color: $bg-color;
    border: solid 1px $border-color;
    color: $color;
    font-size: 1rem;
    line-height: 1.75em;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    padding: .3em $element-spacing-em;

    @include for-screen-gte__hd {
        font-size: $font-size__hd_20;
    }

    &.disabled {
        cursor: default;
        opacity: 0.3;
    }
}

/// hover, focus and active state of default button
/// @param {Color} $color [$base-color__text_light] - text color
/// @param {Color} $border-color [$base-color__border] - border color
/// @param {Color} $bg-color [transparent] - background color
/// @output pseudo attributes for hover, focus and active inc. styles
@mixin base-btn-hover($color: $base-color__text_light, $border-color: $base-color__border_dark, $bg-color: transparent) {
    &:hover,
    &:focus,
    &:active {
        color: $color;
        border-color: $border-color;
        background-color: $border-color;
        text-decoration: none;
    }
}
