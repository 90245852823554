@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/BlogDetail/BlogDetail.js
////

/// styles for blog detail page

.blog-detail {
    .blog-image {
        background: $base-color__bg_grey;

        img {
            width: 100%;
            height: auto;
        }
    }

    .blog-content {
        .text-content {
            background: $base-color__bg_grey;
            padding-bottom: ($grid * 2);
            margin-bottom: ($grid * 2);
            padding-top: $grid-half;

            @include for-screen-lt__xxl { // max-width 1600px
                padding-left: ($grid * 2);
                padding-right: ($grid * 2);
            }

            @include for-screen-lt__md { // max-width 768px
                padding-left: $grid;
                padding-right: $grid;
            }

            &::after {
                content: '';
                clear: both;
                display: block;
            }
        }
    }

    .blog-text {
        @include for-screen-gte__md { // min-width 768px
            columns: 2;
            white-space: pre-wrap;
            padding-top: $grid;
        }

        p {
            line-height: $line-height__default;

            @include for-screen-gte__md { // min-width 768px
                margin: 0;
            }
        }
    }
} // blog-detail
