@import '../../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/ProductDetail/SimilarProducts/SimilarProducts.js
////

/// styles for @TODO
.similar-products {
  clear: both
}