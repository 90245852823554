@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/StoreContactForm/index.js
////

/// styles for store contact form

@import './MoreStoresHint';

.store-contact-form {
    @include details-twocol-halfsize;

    .recipient {
        .field-container {
            display: flex;
            flex-wrap: wrap;

            .field {
                flex: 1 1 100%;
            }

            @include for-screen-gte__md {
                flex-wrap: nowrap;

                .field {
                    &:first-of-type {
                        margin-right: $grid-half;
                    }

                    &:last-of-type {
                        margin-left: $grid-half;
                    }
                }
            }
        }
    }

    .submit-row {
        $margin: 1rem;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -$margin;

        > * {
            margin: $margin $margin $margin 0;
        }

        .captcha-container {
            border: 1px solid transparent;

            &.errors {
                border-color: red;
            }
        }

        .button {
            &:disabled {
                cursor: default;
                opacity: 0.4;
            }
        }
    }

    @import './StoreListWrapper';
}
