@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/StoreDetail/StoreDetail.js
////

/// styles for store detail page

.store-detail {
    @import './BrandProducts';

    .back-link {
        text-transform: none;
    }

    h1.title {
        padding-top: 1rem;
        margin-bottom: 0.2em;

        @include for-screen-gte__xxl { // min-media 1250px
            margin-top: ($grid * 3.25);
        }

        @include for-screen-gte__hd { // min-media 1920px
            margin-top: ($grid * 6.25);
        }

        @include for-screen-lt__xxl { // max-media 1600px
            margin-top: ($grid * 2);
        }

        @include for-screen-lt__xl { // max-media 1250px
            margin-top: ($grid * 1.75);
        }
    }

    .distance {
        @extend %text-light;

        text-transform: uppercase;
        color: $base-color__text_grey;
    }

    .contact-store-button {
        margin-top: (2*$grid);
    }

    .images {
        .image-gallery-image {
            img {
                height: auto;
                aspect-ratio: 16 / 9;
                object-fit: cover;
            }
        }
    }

    .feature-box {
        background: $base-color__bg_grey;
        padding-top: $grid;
        padding-bottom: ($grid * 2);
    }

    .details {
        margin-top: ($grid * 2);

        @import '../Rating';

        .rating {
            margin-bottom: $grid * 2;
        }
    }

    .about-us {
        position: relative;
        margin-bottom: ($grid * 2);

        h4 {
            text-transform: uppercase;
        }

        .text {
            display: none;

            &.active {
                display: initial;
            }
        }

        .language {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: $grid * 2;
            margin: 0;

            span {
                opacity: 0.3;
                text-transform: uppercase;

                &.active,
                &.divider {
                    opacity: 1;
                }
            }
        }
    }

    .details {
        display: flex;
        justify-content: space-between;
        margin-bottom: (3*$grid);

        @include for-screen-gte__xxl { // min-width 1600px
            margin-bottom: (6*$grid);
        }

        @include for-screen-lt__xl { // max-width 1250px
            flex-direction: column;
            justify-content: unset;
            width: 100%;
        }

        .title {
            font-family: $font-family_sans;
            margin-bottom: 1em;

            @include for-screen-lt__md {
                font-size: $font-size__hd_18;
            }
        }

        .map {
            width: 66%;
            min-height: 550px;

            @include for-screen-lt__xl {
                display: block;
                margin-top: $grid;
                width: 100%;
                height: 100vh;
                flex-direction: row;
            }
        }

        .info-label {
            margin-top: 0;
            text-transform: uppercase;
        }
    }

    .brand-list {
        margin-bottom: (3*$grid);

        @include for-screen-gte__xxl {
            margin-bottom: (6*$grid);
        }

        @include items-in-row-content('.brand-item', $grid-half ) {
            display: flex;
            flex: 1 1 0;
            flex-flow: row wrap;
            place-content: stretch flex-start;
            align-items: stretch;
        }

        .brand-item {
            align-items: center;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            text-decoration: none;
            width: 100%;
            text-align: center;

            @include for-screen-gte__xl {
                width: 32.99999999%;
            }

            a {
                display: block;
                text-align:  center;
                width: 100%;
                @extend %text-light;
            }

            &.basic {
                color: $base-color__text_lightgrey;
            }
        }
    }

    @include for-screen-lt__xl {
        .detail-header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                order: 1;
            }

            .header-buttons {
                position: relative;
                transform: none;
                order: 2;

                .button {
                    margin: 0;
                }
            }
        }
    }
}
