.tracking-message {
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    max-width: none;

    border: none;
    box-shadow: 0 -2px 13px 0 rgba(0, 0, 0, 0.15);

    .text {
        p {
            margin: 0;
        }

        a {
            font-weight: bold;
        }
    }

    .buttons {
        align-self: flex-start;

        button {
            border: 0;
            margin: 0;

            path {
                stroke: black;
                stroke-width: 2px;
            }

            &:hover {
                background: transparent;

                path {
                    stroke: $base-color__text_lightgrey;
                }
            }
        }
    }
}
