////
/// @group 00: Utils
/// has to be included and is usable in every component
////

// ++++++++++++++++++++++++++++++++
// text colors
//
/// color for black text
/// @type text color
$base-color__text_dark: #000;
/// color for grey text
/// @type text color
$base-color__text_grey: #1B1C1D;
/// color for light grey text (disabled)
/// @type text color
$base-color__text_lightgrey: #767676;
/// color for white text
/// @type text color
$base-color__text_light: #FFF;
/// default color for text
/// @type text color
$base-color__text: $base-color__text_grey;
/// disabled color for text
/// @type text color
$base-color__text_disabled: rgba($base-color__text_grey, .6);

///  color for error messages
/// @type text color
$base-color__text_error: #89403E;
///  color for warnings
/// @type text color
$base-color__text_warning: #7A790D;
///  color for status messages
/// @type text color
$base-color__text_success: #315F48;

// ++++++++++++++++++++++++++++++++
// background colors
//
/// color for black background
/// @type background color
$base-color__bg_dark: #000;

/// color for grey background
/// @type background color
$base-color__bg_grey: #F8F8F8;

/// variant color for semi transparent dark grey background
/// @type background color
$base-color__bg_greyish: rgba(#767676, .1);

/// variant color for semi transparent light grey background
/// @type background color
$base-color__bg_grey-30: rgba(236, 236, 236, .3);

/// color for white background
/// @type background color
$base-color__bg_light: #FFF;

/// color for semi transparent white background
/// @type background color
$base-color__bg_light-50: rgba($base-color__bg_light, .5);
/// color for semi transparent white background
/// @type background color
$base-color__bg_light-20: rgba($base-color__bg_light, .2);
/// default color for background
/// @type background color
$base-color__bg: $base-color__bg_light;

/// default hover color for background (blog-entry)
/// @type background color
$hover-color__bg_grey: darken($base-color__bg_grey, 5%);

/// disabled background for button
/// @type background color
$base-color__bg_disabled: rgba(darken($base-color__bg_grey, 5%), .7);

///  background color for error messages
/// @type background color
$base-color__bg_error: desaturate(lighten($base-color__text_error, 50), 30%);
///  background color for warnings
/// @type background color
$base-color__bg_warning: desaturate(lighten($base-color__text_warning, 60), 30%);
///  background color for status messages
/// @type background color
$base-color__bg_success: desaturate(lighten($base-color__text_success, 60), 30%);

// ++++++++++++++++++++++++++++++++
// border colors
//
/// color for black border
/// @type border color
$base-color__border_dark: #000;
/// color for grey border
/// @type border color
$base-color__border_grey: #767676;

$base-color__border_lightgrey: #DEDEDF;
/// color for white border
/// @type border color
$base-color__border_light: #FFF;
/// default color for border
/// @type border color
$base-color__border: $base-color__border_grey;
/// disabled color for border
/// @type border color
$base-color__border_disabled: rgba($base-color__border_grey, .2);

///  border color for error messages
/// @type border color
$base-color__border_error: lighten($base-color__text_error, 20);
///  border color for warnings
/// @type border color
$base-color__border_warning: lighten($base-color__text_warning, 20);
///  border color for status messages
/// @type border color
$base-color__border_success: lighten($base-color__text_success, 20);

// component colors
$color_navbar__bg: $base-color__bg;
$color_footer__bg: $base-color__bg;

$color_premium: #767676;
$color_premium_plus: #000;
$color_premium_pro: #F6BC10;

// ++++++++++++++++++++++++++++++++
// grid and spaces
//
/// default grid padding;
/// @type grid and spaces
$grid: 20px;
$grid-half: calc($grid / 2);
$element-spacing: 15px;
$element-spacing-em: .75em;
$element-spacing-em-half: calc($element-spacing-em / 2);

/// default grid padding;
/// @type grid and spaces
$block-spacing: 1rem;

/* Make sure to adjust media query in components/BrandDetail/BrandProductQuery.js
   when changing the following values. */

$screen-width-hd:               1920px;
$screen-width-xxl:              1600px;
$screen-width-xl:               1250px;
$screen-width-lg:               1050px;
$screen-width-for-filter-lg:    1023px;
$screen-width-md:               768px;
$screen-width-sm:               576px;

$container-max-width-hd: 1820px;
$container-max-width-xxl: 1500px;
$container-max-width-xl: 1200px;
$container-max-width-lg: 1000px;

/// navbar
$navbar-height-hd: 100px;
$navbar-height-xxl: 80px;
$navbar-height-xl: 60px;

$navbar-space-hd: ($grid * 2);
$navbar-space-xl: $grid;

/// form
$form-max-width: 500px;
$form-check-radio-size: 18px;


// ++++++++++++++++++++++++++++++++
// animations and transitions
//
$transition: ease-out .3s;

// ++++++++++++++++++++++++++++++++
// Typography
//
/// font family
/// @type font family
$font-family_sans: Overpass, sans-serif;
$font-family_serif: LibreBaskerville, serif;

$line-height__default: 140%;

$font-size__hd_20: 1.25rem;
$font-size__hd_18: 1.125rem;
$font-size__hd_16: 1rem; // for calculate text patrials
$font-size__hd_12: .78rem; // for conversation
