.brand-city-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -$grid 0;

    .value {
        // use button to get the right sizes
        @extend %button;

        color: $base-color__text_lightgrey;
        margin-right: $grid;
        text-align: left;

        &:hover {
            background-color: transparent;
            color: $base-color__text;
        }

        &.city {
            border-top: none;
            border-left: none;
            border-right: none;
            text-transform: none;
            min-width: 30vw;

            padding-right: 1.8em;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70.427 73.427'%3E%3Cpath fill='transparent' stroke='%23767676' stroke-width='2' d='M46.25 49.25l24 24m-14-45a28 28 0 0 1-28 28 28 28 0 0 1-28-28 28 28 0 0 1 28-28 28 28 0 0 1 28 28z'/%3E%3C/svg%3E");
            background-size: 1.3em 1.3em;
            background-repeat: no-repeat;
            background-position: right 0.25em center;
        }

        &.brand {
            padding-right: 1.8em;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 84.355 43.032'%3E%3Cpath fill='transparent' stroke='%23767676' stroke-width='2' d='M83.825-.178L41.88 41.965.536-.174l-.714.7 42.054 42.86L84.533.53z'/%3E%3C/svg%3E");
            background-size: 1.3em 1.3em;
            background-repeat: no-repeat;
            background-position: right 0.25em center;
        }
    }

    .input-container {
        display: flex;
        flex: 1 1 50%;
        max-width: 100%;
        margin-top: $grid;
        margin-right: $grid;

        .value {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .brand {
            margin-right: 0;

            &.all {
                flex-shrink: 0;
            }
        }
    }

    .contact-stores-link-container {
        margin-top: $grid;
    }
}
