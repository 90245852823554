@import '~styles/_settings/utils';

////
/// @group 04: Pages
/// Script Page: assets/scripts/pages/login.js
////

/// styles for user login page

.login-page {
    @include details-twocol-halfsize;

    .main-content {
        margin: auto;

        @include for-screen-gte__xl { // min-media 1250px
            width: 80%;
        }

        @include for-screen-gte__xxl { // min-media 1250px
            width: 70%;
        }
    }

    .details {
        margin: $grid * 4 0;

        a {
            @extend %text-default;
        }

        .details-right {
            padding-top: 0;
        }
    }

    .FAVR-explained,
    .details-left,
    .details-right {
        h2 {
            margin-top: 0;
            margin-bottom: $grid;
        }

        h3 {
            margin-top: -$grid;

            @include for-screen-lt__xl {
                text-align: center;
            }
        }

        .button {
            width: 100%;
            text-transform: none;
        }
    }

    .login-form {
        .field {
            margin-bottom: $grid;
        }
    }

    .FAVR-explained {
        img {
            max-width: 100%;
        }
    }
}
