@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/StoreRegister/Register.js
////

/// styles for store register

.store-register {
    @include details-twocol-halfsize;

    sup {
        font-size: 112%;
        display: contents;
    }

    .help {
        @extend %small;
    }

    h3 {
        b {
            font-weight: inherit;
            text-transform: uppercase;
        }
    }

/* + + + + +  form container  + + + + + */
    .details {
        @include for-screen-gte__md {
            margin-top: ($grid * 3);
            margin-bottom: ($grid * 2);
        }

        @include for-screen-lt__md {
            margin-top: ($grid * 2);
            margin-bottom: ($grid * 2);
        }
    }


/* + + + + +  one line register email form (like conversation) + + + + + */
    .form-line {
        display: flex;
        place-content: stretch flex-start;
        align-items: flex-end;

        // for same height like submit button
        .input {
            @include for-screen-gte__xl {
                font-size: $font-size__hd_16;
            }

            @include for-screen-gte__hd {
                font-size: $font-size__hd_20;
            }
        }

        .field {
            flex-grow: 1;
            margin-bottom: 0;

            &.is-grouped {
                flex-grow: 0;
            }
        }

        .button,
        .input {
            line-height: 1.5;
            padding: .375em .75rem;
        }
    }

}
