@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/EyewearFinder.js
////

/// styles for label eyewear finder

.eyewearfinder {
    $font-size-xl: 2rem;
    $font-size-md: 1.75rem;
    $font-size-xs: 1.5rem;
    $width-xl: 28rem;
    $width-xs: 20rem;

    transform: rotate(-90deg);
    position: fixed;
    top: 20vh;
    right: $grid;
    z-index: 100;
    display: block;
    width: $font-size-xl;
    height: $font-size-xl;

    @include for-screen-lt__xl { // max-media 1250px
        width: $font-size-md;
        height: $font-size-md;
    }

    @include for-screen-lt__lg { // max-media 1050px
        width: $font-size-xs;
        height: $font-size-xs;
    }

    @include max-media(1100px) {
        display: none;
    }

    .label {
        @extend %text-default;

        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        border-bottom: 1px solid $base-color__text_lightgrey;
        font-size: $font-size-xl;
        line-height: 1;
        text-transform: uppercase;
        white-space: nowrap;

        @include for-screen-lt__xl { // max-media 1250px
            font-size: $font-size-md;
        }

        @include for-screen-lt__lg { // max-media 1050px
            font-size: $font-size-xs;
        }
    }
}
