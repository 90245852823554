.product-request {
    .message {
        .details {
            .details-left > p {
                margin-top: 0;
                margin-bottom: $grid;
            }

            .details-right {
                padding-top: 0;
                display: flex;

                label {
                    line-height: 140%;
                    margin-bottom: $grid;
                }
            }
        }

        .field {
            margin-bottom: $grid * 2;  /* same as product info */
        }

        textarea {
            height: 11rem;

            @include for-screen-gte__xl {
                height: 12rem;
            }

            @include for-screen-gte__xxl {
                height: 14rem;
            }

            @include for-screen-gte__hd {
                height: 16.4rem;
            }
        }
    }

    @import './ProductInfo';
}
