.store-links {
    list-style: none;
    margin-left: 0;
    text-align: center;

    li {
        display: inline-block;
        margin: 0;
        padding: 1em;

        a {
            color: $base-color__text_lightgrey;
        }
    }
}
