.brand-city-search-modal {

    ul.brand-list {
        margin-top: $grid;
        list-style-type: none;

        li {
            text-align: center;
            line-height: $line-height__default;
            padding: $grid-half;
            font-size: 120%;
            cursor: pointer;

            &:hover {
                background-color: $base-color__bg_grey;
            }

            &.group {
                cursor: default;
                margin-top: $grid;
                font-size: 140%;
                font-weight: bold;
                text-transform: uppercase;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    .city-search-container {
        padding: $grid-half;

        .city-search {
            input {
                width: 100%;
                border: none;
            }

            padding-bottom: .3rem;
        }
    }

    .modal-content {
        padding-bottom: $grid*2;
    }
}

// Google Maps generated root class
.pac-container {
    z-index: 10001;
}
