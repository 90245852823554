@import '../_settings/utils';

.store-locator.curated {
    h2,
    .intro-text {
        text-align: center;
    }

    .intro-text {
        padding-top: $grid;
    }
}
