@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/BlogTeaser/BlogTeaserList.js
////

/// styles for blog list

.blog-list {
    margin-top: ($grid * 2);

    .title {
        margin-top: 0;
    }

    .subtitle {
        margin-top: 0;
    }

    .blog-flex {
        display: flex;
        justify-content: space-between;

        @include for-screen-lt__md { // max-width 768px
            flex-direction: column;
        }

        // only blog text
        .text-content {
            flex: 1 1 auto;
        }

        // blog image
        .blog-image {
            flex: 0 0 25%;

            @include for-screen-gte__xl { // min-width 1250px
                padding-left: ($grid * 2);
            }

            @include for-screen-lt__xl { // max-width 1250px
                padding-right: $grid;
            }

            @include for-screen-lt__md { // max-width 768px
                flex: 1 1 100%;
                padding-right: 0;
                padding-bottom: $grid;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .blog-entry {
        position: relative;
        margin-bottom: $grid;
        padding-top: $grid;
        padding-bottom: $grid;

        @include for-screen-gte__xxl { // min-width 1920px
            padding-top: ($grid * 2);
            padding-bottom: ($grid * 2);
        }

        .badge {
            background: $base-color__bg_dark;
            color: $base-color__text_light;
            padding: 0.25rem;
        }

        /* + + +  hover behaviour  + + + */
        a {
            > div {
                position: relative; // content is laying over "before"
            }

            // desktop hover: border
            // mobile hover: new background
            &::before {
                transition: border-color $transition;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: auto;
                background-color: $base-color__bg_grey;
                border: solid 1px $base-color__bg_grey;

                @include for-screen-lt__xl { // max-width 1250px
                    transition: background-color $transition;
                    left: -50%;
                    right: -50%;
                    width: auto;
                    border: 0 none;
                }
            }

            &:hover {
                text-decoration: none;

                &::before {
                    border-color: $base-color__border_grey;

                    @include for-screen-lt__xl { // max-width 1250px
                        background-color: $hover-color__bg_grey;
                    }
                }
            }
        }
    } // blog-entry
} // blog-list
