@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/StoreLocator/StoreList.js
////


// define animations for error messages
@include define-color-flash-animation('success-flash', darken(saturate($base-color__bg_success, 10%), 40%), $base-color__bg_light, $base-color__border_success, $base-color__border_grey, $base-color__text_success, desaturate($base-color__text_success, 30%));
@include define-color-flash-animation('error-flash', darken(saturate($base-color__bg_error, 10%), 40%), $base-color__bg_light, $base-color__border_error, $base-color__border_grey, $base-color__text_error, desaturate($base-color__text_error, 30%));
@include define-color-flash-animation('warning-flash', darken(saturate($base-color__bg_warning, 10%), 40%), $base-color__bg_light, $base-color__border_warning, $base-color__border_grey, $base-color__text_warning, desaturate($base-color__text_warning, 30%));

/// styles for store list, store item
.messages {
    &.overlay {
        $item-height: ($grid * 2);
        $icon-size: 16px;

        .message-list {
            // top position + right-aligned with navbar
            @include section;

            display: inline-block;
            width: auto;
            list-style: none;

            @include for-screen-gte__xl { // min-width 1250px
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
                margin-bottom: $navbar-space-xl;
            }

            @include for-screen-gte__hd { // min-width 1920px
                padding-top: 0;
                margin-bottom: $navbar-space-hd;
            }

            @include for-screen-lt__xl { // max-width 1250px
                margin: 0 20px;
            }
        }

        .message-item {
            transition: background-color $transition, color $transition;
            position: relative;
            display: flex;
            min-height: $item-height;
            align-items: center;
            justify-content: flex-start;
            text-align: left;

            @include use-animation('success-flash');
            border: 1px solid $base-color__border_grey;
            color: desaturate($base-color__text_success, 100%);
            background-color: $base-color__bg_light;

            &:hover {
                background-color: opacify($base-color__bg_success, .2);
            }

            + .message-item {
                border-top: 0 none;
            }

            &.is-error {

                @include use-animation('error-flash');
                color: desaturate($base-color__text_error, 20%);

                &:hover {
                    background-color: opacify($base-color__bg_error, .2);
                }
            }

            &.is-warning {
                @include use-animation('warning-flash');
                color: desaturate($base-color__text_warning, 40%);

                &:hover {
                    background-color: opacify($base-color__bg_warning, .2);
                }
            }
        }

        .message-content {
            flex: 1 1 auto;
            padding: $grid-half $item-height $grid-half $grid-half;
        }
    }
}
