@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/BaseDetail.js
////

// @TODO refactoring

/// styles for @TODO
.images {
    display: flex;

    .image {
        margin-right: .75em;

        img {
            width: auto;
            max-height: 300px;
        }
    }
}
