////
/// @group 00: Utils
/// has to be included and is usable in every component
////

/// default modal
/// @param {background-color} $modal-background [$base-color__bg_dark] - background color modal (overlay)
/// @param {background-color} $modal-card-background [$base-color__bg_light] - background color card

@mixin modal($modal-background: $base-color__bg_dark, $modal-card-background: $base-color__bg_light) {

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .modal-background {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba($modal-background, .3);
    }

    .modal-card {
        position: relative;
        z-index: 2;
        top: 25vh;
        margin: 0 auto;
        max-width: calc(100vw - 40px);
        width: 500px;
        max-height: 70vh;
        overflow: auto;
        background-color: $modal-card-background;

        @include for-screen-gte__xl {
            max-width: ($container-max-width-xl - ($grid*4));
            top: 141px;
            max-height: calc(98vh - 141px);
        }

        @include for-screen-gte__xxl {
            max-width: ($container-max-width-xxl - ($grid*4));
            top: 161px;
            max-height: calc(98vh - 161px);
        }

        @include for-screen-gte__hd {
            max-width: ($container-max-width-hd - ($grid*8));
            top: 181px;
            max-height: calc(98vh - 181px);
        }
    }

    .modal-card-head {
        padding: ($grid * 2) ($grid * 2) $grid;
        margin: 0;

        .button {
            display: flex;
            align-items: center;

            &.is-close {
                position: absolute;
                top: $grid;
                right: $grid;
                z-index: 100;
                display: inline-block;
                width: auto;
                height: auto;
                border: 0 none;

                svg.close {
                    width: 21px;
                    height: 21px;
                }
            }

            .label {
                margin: 0;
            }
        }
    }

    .modal-card-body {
        padding: 0 ($grid * 2);

        @include for-screen-gte__xl {
            padding: 0 ($grid * 4);
        }

        .button {
            display: block;
        }
    }

    .modal-card-foot {
        padding: $grid ($grid * 2) ($grid * 2);

        button {
            border-width: 1px;
        }

        @include for-screen-gte__xl {
            padding: ($grid * 2) ($grid * 4) ($grid * 2);
        }
    }

    @include for-screen-lt__md {
        .modal-card {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            max-height: 100vh;
            flex: 1 1 0;
            flex-flow: column nowrap;
            place-content: stretch flex-start;
            align-items: stretch;
        }

        .modal-card-head,
        .modal-card-foot {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .modal-card-body {
            display: flex;
            justify-content: flex-start;
            flex-grow: 1;
            position: relative;
            overflow: auto;
            flex-flow: column;
            align-items: stretch;
            width: 100%;
        }
    }

    @content;
}

