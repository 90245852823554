@import '../../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/BrandDetail/BrandDetail.js
////

/// styles for brand detail page

.brand-detail {
    .title {
        margin-bottom: .2em;
    }

    .subtitle {
        margin-top: 0;
        margin-bottom: (2 * $grid);
    }

    .intro {
        margin-top: 0;
    }

    #brand-products {
        .title {
            margin-bottom: $grid;
        }

        .product-filter-list {
            margin-top: $grid * 1.5;
        }

        .selected-filter-wrapper {
            .label {
                margin-bottom: 0;
            }
        }

        .selected-filter-list {
            margin-bottom: 0;

            .selected-filter-item {
                margin-bottom: 0;
                margin-top: $grid;
            }

            .clear-all {
                margin-top: calc(#{$grid} - 0.5em);
            }
        }

        .product-list {
            margin-top: $grid * 2;
        }
    }

    .product-filters {
        .filter-type {
            gap: $grid;
            justify-content: center;
        }

        .selected-filter-list {
            margin-bottom: 0;
        }
    }

    .buttons {
        margin: (2 * $grid) 0;
        display: flex;
        justify-content: center;
        gap: $grid-half;

        .button {
            font-size: 85%;
        }
    }

    .store-locator,
    .store-owner {
        margin: (2 * $grid) 0;
    }

    .brand-profile {
        margin-top: (4 * $grid);
    }

    .images {
        .image-gallery-image {
            img {
                height: auto;
                aspect-ratio: 16 / 9;
                object-fit: cover;
            }
        }
    }

    .description {
        columns: 2;
        padding: (2*$grid) 0;
        line-height: 140%;
        white-space: pre-wrap;

        // Intro
        p:first-child {
            column-span: all;
            font-size: 140%;
            margin-bottom: $grid;
        }

        p {
            break-inside: avoid;
        }

        // fix line-height issue in headlines
        h1, h2, h3, h4, h5 {
            line-height: normal;
            margin: 0;
            break-after: avoid;
        }

        // no columns on mobile:
        @include for-screen-lt__xl {
            columns: 1;
        }
    }

    &.simple {
        .intro {
            margin: $grid 0;
        }

        .description {
            padding: 0;
        }
    }
}

@import './BrandContent';
@import './BrandPosts';
