@import '../_settings/utils';

////
/// @group 03: Components
/// Script Component: assets/scripts/components/LanguageChanger.js
////

/// styles for LanguageChanger in nav menu
.language-changer {
    position: absolute;
    top: -2.25em;
    right: 0;
    text-transform: uppercase;
    display: block;

    .top-menu-item {
        float: left;
        font-weight: 500;
        margin: 1px;
        padding: .35em .75em;

        @include for-screen-lt__xl {
            display: none;
        }

        @include for-screen-lt__hd {
            font-size: .83em;
            padding: .15em .75em;
        }
    }

    ul {
        position: relative;
        width: auto;
        left: 0;
        top: 0;
        height: 100%;
        overflow: visible;
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
    }

    li {
        margin: 0;
        padding: 0;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        opacity: .3;
        cursor: default;

        span {
            border: 1px solid transparent;
            padding: .35em .75em;
        }

        &.current {
            span {
                font-weight: 500;
                background: $base-color__bg_grey;
            }
        }

        &.current,
        &.available {
            opacity: 1;
            cursor: pointer;
        }
    }

    @include for-screen-lt__xl {
        position: static;

        .language-list {
            display: none;
        }
    }

    @include for-screen-lt__hd {
        top: -1.4em;

        li {
            font-size: .83em;

             span {
                 padding: .15em .75em;
             }
        }

    }
}
