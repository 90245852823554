////
/// @group 01: Globals
/// global elements imported in Layout.scss which are valid cross component
/// do not import the globals in standard components!!!!
////

%form-element {
    @include set-colors($base-color__text_dark, $base-color__bg_light, $base-color__border_grey);

    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    display: block;
    box-sizing: border-box;
    background-clip: padding-box;
    border: 1px solid;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    padding: .375em .75rem;

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        color: #495057;
    }
}

%button-select {
    @include  base-btn($base-color__text_dark, false, $base-color__border_grey);
    -webkit-appearance:none;
    border-radius: 0;
    border: 1px solid $base-color__border_grey;
    margin-left: $element-spacing-em;
}

.button-select {
    @extend %button-select;
}

%default-label {
    position: relative;
    display: inline-block;
    margin-bottom: .5rem;
}

%top-label {
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: .5rem;
}

select,
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='url'],
input[type='number'],
input[type='password'],
.pseudo-input,
textarea {
    @extend %form-element;

    border-radius: 0; // ios
}

.pseudo-input {
    background-color: $base-color__bg_grey;
    color: $base-color__text_lightgrey;
    font-style: italic;
    margin-top: .5rem;
}

.field {
    margin-bottom: ($grid * 1.5);

    .label {
        @extend %default-label;

        &.top-label {
            @extend %top-label;
        }
    }

    select {
        appearance: none;
        padding-left: .8em;
        background-repeat: no-repeat;
        background-position: right .25em top 65%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='2 2 24 24'%3E%3Cpath d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E");

        &::-ms-expand {
            display: none;
        }
    }

    input,
    select,
    textarea {
        width: 100%;
    }

    textarea {
        height: 10rem;
    }
}

/* + + + + +  checkbox, radiobutton  + + + + + */
.checkbox,
.radio {
    $checked-size: $form-check-radio-size - 6;

    position: relative;
    display: block;
    padding-left: $grid-half + $form-check-radio-size;

    .label {
        position: relative;
        top: -1px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        top: -2px;
        left: 0;
        width: $form-check-radio-size;
        height: $form-check-radio-size;
        border: 0;

        $form-check-radio-size-center: calc(($form-check-radio-size - $checked-size) / 2);

        &:checked::after {
            content: '';
            position: absolute;
            top: $form-check-radio-size-center;
            left: $form-check-radio-size-center;
            display: block;
            width: $checked-size;
            height: $checked-size;
            box-sizing: border-box;
            background-color: $base-color__border_grey;
        }

        &::before {
            content: '';
            display: block;
            width: $form-check-radio-size;
            height: $form-check-radio-size;
            box-sizing: border-box;
            background-color: #FFF;
            border: 1px solid $base-color__border_grey;
        }

        &[disabled] {
            &::before {
                border-color: $base-color__border_disabled;
            }

            &::after {
                background-color: $base-color__border_disabled;
            }

            + span {
                color: $base-color__text_disabled;
            }
        }
    }
}

.checkbox {
    input[type="checkbox"] {
        border-radius: 0;
    }
}

.radio {
    input[type="radio"] {
        &:checked::after {
            border-radius: 50%;
        }

        &::before {
            border-radius: 50%;
        }
    }
}
