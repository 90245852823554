.brand-list {
    margin-top: 2 * $grid;

    .brand-item {
        text-align: center;

        a {
            img {
                height: auto;
                aspect-ratio: 16 / 9;
                object-fit: cover;
            }

            &:hover,
            &:active {
                text-decoration: none;

                img {
                    opacity: 0.8;
                }
            }
        }

        .title {
            margin-top: $grid;
            margin-bottom: .3em;
        }

        .subtitle {
            text-transform: uppercase;
            padding-bottom: ($element-spacing-em *2);
            min-height: 2.5em;
            color: $base-color__text_grey;
            @extend %text-thin;
        }
    }
}
