@import '../_settings/utils';


div.divider {
    width: 100%;
    margin: 5px;

    border-color: rgba($base-color__border_dark, 0.6);
    border-right: 1px solid rgba($base-color__border_dark, 0.3);
    border-bottom: 1px solid rgba($base-color__border_dark, 0.3);
}
