////
/// provides cross-browser consistency
/// @group 01: Globals
/// global normalize styles are imported in App.scss which are valid cross component
/// do not import these globals in standard components!!!!
////

/// mixin with normalize styles
@mixin normalize {

    html {
        line-height: 1.15; // Adjust line height in all browsers
        -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS.
    }

    body {
        font-family: $font-family_sans;
        margin: 0; // Remove the margin in all browsers.
    }

    main {
        display: block; // Render the `main` element in IE as block element
    }

    div {
        box-sizing: border-box;
    }

    figure,
    div,
    ul,
    ol,
    dl,
    dd {
        margin: 0;
        padding: 0;
    }

    h1 {
        font-size: 2em; // Adjust font size of h1 in section and article container in Chrome, FF and SF
        margin: 0.67em 0; // Adjust margin of h1 in section and article container in Chrome, FF and SF
    }

    hr {
        // Adjust box sizing for FF
        box-sizing: content-box;
        height: 0;
        // Do not hide overflow in Edge and IE
        overflow: visible;
    }

    pre {
        font-family: monospace, monospace; // Adjust inheritance and scaling of font size in all browsers
        font-size: 1em; // Adjust font sizing in all browsers.
    }

    abbr[title] {
        border-bottom: none; // Remove bottom border in old versions of Chrome (57 and lower)
        // Adjust text decoration in Chrome, Edge, Opera and SF.
        text-decoration: underline; // For browsers that do not support text-decoration-style for text-decoration
        text-decoration: underline dotted; // For browsers that support text-decoration-style for text-decoration
    }

    b,
    strong {
        font-weight: bolder; // Adjust font weight in Chrome, Edge, and SF
    }

    code,
    kbd,
    samp {
        font-family: monospace, monospace; // Adjust inheritance and scaling of font size
        font-size: 1em; // Adjust font sizing
    }

    small {
        font-size: 80%; // Adjust font sizing
    }

    // Prevent `sub` and `sup` elements from affecting the line height
    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    sub {
        bottom: -0.25em;
    }

    sup {
        top: -0.5em;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        // Adjust font styles
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        // Remove margin in FF and SF.
        margin: 0;
    }

    button,
    input,
    select {
        overflow: visible; // Show overflow in Edge
        &:focus {
            outline: none;
        }
    }

    button,
    select {
        text-transform: none; // Remove the inheritance of text transform in Edge, FF, and IE
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
        -webkit-appearance: button; // Correct the inability to style clickable types in iOS and SF
    }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
        // Remove inner border and padding in FF
        border-style: none;
        padding: 0;
    }

    button:-moz-focusring,
    [type="button"]:-moz-focusring,
    [type="reset"]:-moz-focusring,
    [type="submit"]:-moz-focusring {
        outline: 1px dotted ButtonText; // Restore the focus styles unset by the previous rule
    }

    fieldset {
        padding: 0.35em 0.75em 0.625em; // Adjust padding in FF
    }

    legend {
        // Adjust text wrapping in Edge
        box-sizing: border-box;
        white-space: normal;
        display: table;
        max-width: 100%;
        // Adjust color inheritance from fieldset in IE
        color: inherit;
        padding: 0;

    }

    progress {
        vertical-align: baseline; // Adjust vertical alignment in Chrome, FF and Opera
    }

    textarea {
        overflow: auto; // Remove vertical scrollbar in 11
    }

    [type="checkbox"],
    [type="radio"] {
        // Adjust box sizing and padding in IE 10
        box-sizing: border-box;
        padding: 0;
    }

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
        height: auto; // Adjust cursor style of increment and decrement buttons in Chrome
    }

    [type="search"] {
        -webkit-appearance: textfield; // Adjust appearance in Chrome and SF
        outline-offset: -2px; // Adjust outline in SF
    }

    [type="search"]::-webkit-search-decoration {

        -webkit-appearance: none; // Remove inner padding in Chrome and SF on macOS
    }

    ::-webkit-file-upload-button {
        -webkit-appearance: button; // allow to style clickable types in iOS and Safari
        font: inherit; // inherit font properties in SF
    }

}
